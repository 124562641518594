import styled from 'styled-components/macro'
import { Box } from 'rebass'
import { Trans } from '@lingui/macro'
import { isMobile } from 'utils/userAgent'
import Footer from 'components/Footer/Footer'
// import { ThemedText } from 'theme'
import ContentImageCard from 'pages/Home/ContentImageCard'

import BestAudience from '../../assets/svg/Best_Audience.svg'
import NewsCoverage from '../../assets/svg/news_coverage.svg'
import Featured from '../../assets/svg/featured_on_best_dex.svg'

import PartnerDescription from './PartnerDescription'
// import PartnerForm from './PartnerForm'
import PartnerApplicationForm from './PartnerApplicationForm'
import { useEffect } from 'react'
// const Container = styled.div`
//   margin: 0px 120px;
//   @media screen and (max-width: 1150px) {
//     margin: 0px 30px;
//   }
//   @media screen and (max-width: 1060px) {
//     margin: 0px 50px;
//   }
//   @media screen and (max-width: 600px) {
//     margin: 0px 10px;
//   }
// `

const HeroBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background: linear-gradient(180deg, #5860ff 37.64%, #ffffff 203.72%);
  height: 400px;
  width: 100%;
  padding: 0px 120px;
  @media screen and (max-width: 1150px) {
    padding: 0px 30px;
  }
  @media screen and (max-width: 1000px) {
    padding: 0px 0px 40px 0px;
    height: 400px;
  }
  @media screen and (max-width: 600px) {
    padding: 0px 0px 40px 0px;
    height: 380px;
  }
`

const HeroInner = styled(Box)<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding-top: ${({ isMobile }) => (isMobile ? '82px' : '40px')};
`

// const HeroTitle = styled(Box)<{ isMobile: boolean }>`
//   color: #fff;
//   text-align: center;
//   font-family: Poppins;
//   font-size: ${({ isMobile }) => (isMobile ? '32px' : '72px')};
//   font-style: normal;
//   font-weight: 700;
//   line-height: 115%; /* 82.8px */
//   // line-height: 51.6px; /* 120% */
//   @media screen and (max-width: 600px) {
//     width: 321px;
//   }
// `

// const HeroDescription = styled(Box)`
//   text-align: center;
//   font-family: Poppins;
//   font-size: 20px;
//   font-style: normal;
//   font-weight: 500;
//   max-width: 800px;
//   margin-top: 16px !important;
//   line-height: 24px; /* 150% */
//   @media screen and (max-width: 800px) {
//     margin-top: 10px !important;
//     font-size: 16px;
//   }
// `

const CardHeader = styled(Box)`
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
  color: #000000;
`

const CardSubtitle = styled(Box)`
  text-align: center;
  font-family: Poppins;
  color: #868686;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  margin-top: 8px !important;
  line-height: 24px; /* 150% */
  @media screen and (max-width: 800px) {
    margin-top: 10px !important;
    font-size: 16px;
  }
`

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  max-width: 1440px;
  gap: 3%;
  padding: 0px 120px;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0;
    // padding: 0px 15px;
    padding: 0px;
  }
`
// const HeaderText = styled(ThemedText.Black)`
//   text-align: center;
//   font-size: 48px;
//   font-weight: 600 !important;
//   @media screen and (max-width: 800px) {
//     font-size: 32px;
//   }
// `
const Section = styled.section`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 800px) {
    margin-top: 32px;
    // padding: 0px 10px;
    padding: 0px;
  }
`
const FormContainer = styled.div`
  width: 100%;
  padding: 0px 120px;
  max-width: 1440px;
  @media screen and (max-width: 800px) {
    padding: 0px 16px;
  }
`
//
const HeroTitleP = styled.p<{ isMobile: boolean }>`
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: ${({ isMobile }) => (isMobile ? '32px' : '72px')};
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 82.8px */
  // line-height: 51.6px; /* 120% */
  @media screen and (max-width: 600px) {
    width: 321px;
    font-weight: 600;
    line-height: 48px;
  }
`
const HeaderTextP = styled.p`
  color: #0f0e0e;
  text-align: center;
  font-size: 48px;
  font-weight: 600;
  @media screen and (max-width: 800px) {
    font-size: 32px;
  }
`
const HeroDescriptionP = styled.p`
  color: #0f0e0e;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  max-width: 800px;
  margin-top: 16px !important;
  line-height: 24px; /* 150% */
  @media screen and (max-width: 800px) {
    margin-top: 10px !important;
    font-size: 16px;
    padding: 0px 16px;
  }
`
function Partner() {
  const CardList = [
    {
      id: 'Airdrop_card_1',
      heading: <Trans>Best Audience</Trans>,
      subtitle: (
        <Trans>
          You have a following and you’re trending in all the right ways. Best Wallet will showcase your achievements
          and potential to thousands more engaged crypto holders.
        </Trans>
      ),
      imageSrc: BestAudience,
    },
    {
      id: 'Airdrop_card_2',
      heading: <Trans>News Coverage</Trans>,
      subtitle: (
        <Trans>
          Your project will be picked up by high-ranking crypto news sites with a combined traffic of over 20 million
          monthly visitors.
        </Trans>
      ),
      imageSrc: NewsCoverage,
    },
    {
      id: 'Airdrop_card_3',
      heading: <Trans>Featured on Best DEX</Trans>,
      subtitle: (
        <Trans>
          Be a part of the Best Ecosystem. We’ll list your token on Best DEX and support it with exposure and user
          incentives.
        </Trans>
      ),
      imageSrc: Featured,
    },
  ]
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <HeroBanner>
        <HeroInner width={'100%'} isMobile={isMobile}>
          {/* <HeroTitle isMobile={isMobile}> */}
          <HeroTitleP isMobile={isMobile}>
            <Trans>Get Featured on Best DEX</Trans>
          </HeroTitleP>
          {/* </HeroTitle> */}
        </HeroInner>
      </HeroBanner>
      <Section>
        <HeaderTextP>
          <Trans>Partner with the Best</Trans>
        </HeaderTextP>
        <HeroDescriptionP>
          <Trans>
            Become a partner and let us showcase your project to the world. Reach thousands of highly engaged global
            Best Wallet crypto holders.
          </Trans>
        </HeroDescriptionP>
        <CardContainer>
          {CardList.map((card) => (
            <ContentImageCard TextPosition={'BOTTOM'} key={card.id} imageSrc={card.imageSrc}>
              <>
                <CardHeader>
                  <Trans>{card.heading}</Trans>
                </CardHeader>
                <CardSubtitle>
                  <Trans>{card.subtitle}</Trans>
                </CardSubtitle>
              </>
            </ContentImageCard>
          ))}
        </CardContainer>
      </Section>
      <div>
        <PartnerDescription />
      </div>
      <FormContainer>
        {/* <PartnerForm /> */}
        <PartnerApplicationForm />
      </FormContainer>
      <Footer />
      {/* <iframe src="https://dashboard.withblaze.app/forms/7c92e3dea64ecb343752344d4e5aef7f" title="description"></iframe> */}
    </>
  )
}

export default Partner
