import '@reach/dialog/styles.css'
import 'inter-ui'
import 'polyfills'
import 'components/analytics'
// import '@fontsource/barlow/500.css'
// import '@fontsource/barlow/700.css'
// import '@fontsource/barlow-condensed/500.css'
// import '@fontsource/barlow-condensed/700.css'
// import '@fontsource/barlow-semi-condensed/500.css'
// import '@fontsource/barlow-semi-condensed/700.css'

// import '@fontsource/work-sans/500.css'
// import '@fontsource/work-sans/700.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'

import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { NetworkContextName } from './constants/misc'
import { ToastContainer } from 'react-toastify'
import { LanguageProvider } from './i18n'
import App from './pages/App'
import store from './state'
import ThemeProvider, { ThemedGlobalStyle } from './theme'
import getLibrary from './utils/getLibrary'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

// function Updaters() {
//   return (
//     <>
//       <RadialGradientByChainUpdater />
//       <ListsUpdater />
//       <UserUpdater />
//       <ApplicationUpdater />
//       <TransactionUpdater />
//       <MulticallUpdater />
//       <LogsUpdater />
//     </>
//   )
// }

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <LanguageProvider>
          <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ProviderNetwork getLibrary={getLibrary}>
              {/* <Blocklist> */}
              {/* <Updaters /> */}
              <ThemeProvider>
                <ThemedGlobalStyle />
                <ToastContainer />
                <App />
              </ThemeProvider>
              {/* </Blocklist> */}
            </Web3ProviderNetwork>
          </Web3ReactProvider>
        </LanguageProvider>
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)

// if (process.env.REACT_APP_SERVICE_WORKER !== 'false') {
//   serviceWorkerRegistration.register()
// }
