import React, { ReactElement } from 'react'
import { isMobile } from 'utils/userAgent'
import styled from 'styled-components/macro'

const CircleBullet = styled.span`
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  border: 1px solid #cdcdcd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222933;
  border-radius: 50%;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

const ListItem = styled.span<{ fontSize: string }>`
  display: flex;
  align-items: center;
  color: #2b2b2b;
  font-family: Poppins;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '20px')};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 16px;
`

type ListNumberProps = {
  number: string
  content: string | ReactElement
}

function ListNumber({ number, content }: ListNumberProps) {
  return (
    <>
      <CircleBullet>{number}</CircleBullet>
      <ListItem fontSize={isMobile ? '16px' : '20px'}>{content}</ListItem>
    </>
  )
}

export default ListNumber
