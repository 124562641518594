import Loader from 'components/Loader'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import { Suspense, useEffect } from 'react' // lazy
import { Route, Switch } from 'react-router-dom' // Redirect

import styled from 'styled-components/macro'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import Home from './Home'
import { RedirectPathToHomeOnly, RedirectPathToPageNotFoundOnly } from '../pages/Home/redirects'
import Airdrop from './Airdrop'
import Partner from './Partner'
import Terms from './Terms'
import Policy from './Policy'
import Cookies from './Cookies'
import useContentPage from 'hooks/useContentPage'
import PageNotFound from './PageNotFound'
import GlobalLoader from 'components/GlobalLoader'
import CacheBuster from 'react-cache-buster'
import { version } from '../../package.json'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 120px 16px 0px 16px;
  align-items: center;
  flex: 1;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 6rem 16px 16px 16px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function App() {
  // const location = useLocation()
  // const [isActiveHeader, setActiveHeader] = useState(true)
  // const isProduction = process.env.NODE_ENV === 'development'
  const isContentPage = useContentPage()
  const IS_AIRDROP_ALLOWED = process.env.REACT_APP_BETA_FEATURE_FLAG ?? ''
  useEffect(() => {
    const { ethereum } = window
    if (
      (window as any)?.ethereum &&
      (window as any)?.ethereum?.providers?.length > 1 &&
      (ethereum as any)?.selectedProvider?.isCoinbaseWallet
    ) {
      if (typeof ethereum === 'object') {
        ;(ethereum as any)?.selectedProvider?.close()
      }
    }
  }, [])

  // useEffect(() => {
  //   if (location?.pathname === '/terms' || location?.pathname === '/privacy' || location?.pathname === '/cookies') {
  //     setActiveHeader(false)
  //   } else {
  //     setActiveHeader(true)
  //   }
  // }, [location?.pathname])
  console.log('pkg json version', version)
  return (
    <>
      {/* // CacheBuster Props :
// reloadOnDowngrade={true} : determines whether the
// app should reload when the detected version is older than currently running version

//metaVaerion: meta.json file

//current version :  version from package file

// metaFileDirectory={'./meta.json'} or {'.'}

// isEnabled={window.caches === undefined ? false : true} 
*/}

      {/* Note: for CachBuster
      Keep it mind at every deployment make sure currentVersion should updated 
      i.e change version at every deployment,
      So user can see latest changes on one refresh only.
      n also make sure do not give currentVersion lesser than metaVersion,
      If currentVersion < metaVersion it goes infinite refresh loop
      */}
      <CacheBuster
        currentVersion={version}
        isEnabled={true}
        reloadOnDowngrade={false}
        isVerboseMode={true}
        loadingComponent={<Loader />}
        metaFileDirectory={'.'}
        // isEnabled={window.caches === undefined ? false : true}
      >
        <ErrorBoundary>
          <Route component={GoogleAnalyticsReporter} />
          <Route component={DarkModeQueryParamReader} />
          <Route component={ApeModeQueryParamReader} />
          <Web3ReactManager>
            <AppWrapper>
              {/* <HeaderWrapper>{isActiveHeader ? <Header /> : ''}</HeaderWrapper> */}
              <GlobalLoader></GlobalLoader>
              <HeaderWrapper>
                <Header />
              </HeaderWrapper>
              <BodyWrapper style={isContentPage ? { padding: '0px' } : {}}>
                <Popups />
                {/* <Polling /> */}
                {/* <TopLevelModals /> */}
                <Suspense fallback={<Loader />}>
                  <Switch>
                    {/* Route for home page */}
                    <Route exact strict path="/" component={Home} />
                    {IS_AIRDROP_ALLOWED === 'true' ? <Route exact strict path="/airdrop" component={Airdrop} /> : null}
                    {/* {IS_AIRDROP_ALLOWED === 'true' ? <Route exact strict path="/partner" component={Partner} /> : null} */}
                    <Route exact strict path="/partner" component={Partner} />
                    <Route exact strict path="/terms" component={Terms}></Route>
                    <Route exact strict path="/privacy" component={Policy}></Route>
                    <Route exact strict path="/cookies" component={Cookies}></Route>

                    {window.location.hash === '#features-scroll' || window.location.hash === '#/' ? (
                      <Route component={RedirectPathToHomeOnly} />
                    ) : (
                      <>
                        <Route exact strict path="/pagenotfound" component={PageNotFound} />
                        <Route path="*" component={RedirectPathToPageNotFoundOnly} />
                      </>
                    )}
                  </Switch>
                </Suspense>
                <Marginer />
              </BodyWrapper>
            </AppWrapper>
          </Web3ReactManager>
        </ErrorBoundary>
      </CacheBuster>
    </>
  )
}
