import React from 'react'
import Slider from 'react-slick'
// import { ThemedText } from 'theme'
import TradeIcon from '../../assets/svg/trading-icon.svg'
import LowFeesIcon from '../../assets/svg/low-fees-icon.svg'
import DecentralizeIcon from '../../assets/svg/decentralized-icon.svg'
import styled from 'styled-components/macro'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { Box } from 'rebass'
import { Trans } from '@lingui/macro'

const IconImage = styled.img``
// const Description = styled(ThemedText.DarkGray)`
//   font-weight: 500;
//   font-size: 20px;
//   line-height: 30px;
//   color: #868686;
//   @media screen and (max-width: 600px) {
//     font-size: 18px;
//   }
// `

const Container = styled(Box)`
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: center;
`
const Wrapper = styled.div`
  display: none;
  margin-bottom: 26px;
  @media screen and (max-width: 850px) {
    display: block;
  }
`
//
const DescriptionH4 = styled.h4`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #868686;
  text-align: center;
  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
`
const FeaturesCarousal = React.memo(function HeroCarousal() {
  const sliderSettings = {
    arrow: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  // const logoList = [
  //   {
  //     id: 1,
  //     url: TradeIcon,
  //     alt: 'Be in Crypto',
  //     subtitle: 'Trading Insights',
  //   },
  //   {
  //     id: 2,
  //     url: LowFeesIcon,
  //     alt: 'Low Fees',
  //     subtitle: 'Low Fees',
  //   },
  //   {
  //     id: 3,
  //     url: DecentralizeIcon,
  //     alt: 'Decentralised & Secure',
  //     subtitle: 'Decentralised & Secure',
  //   },
  // ]
  return (
    <Wrapper>
      <Slider
        prevArrow={<ChevronLeft color="#5860ff"></ChevronLeft>}
        nextArrow={<ChevronRight color="#5860ff"></ChevronRight>}
        {...sliderSettings}
      >
        {/* {logoList.map((logoItem) => {
          return (
            <Container key={logoItem.id}>
              <IconImage src={logoItem.url} />

              <DescriptionH4>
                <Trans>{logoItem.subtitle}</Trans>
              </DescriptionH4>
            </Container>
          )
        })} */}

        <Container>
          <IconImage src={TradeIcon} />

          <DescriptionH4>
            <Trans>Trading Insights</Trans>
          </DescriptionH4>
        </Container>

        <Container>
          <IconImage src={LowFeesIcon} />

          <DescriptionH4>
            <Trans>Low Fees</Trans>
          </DescriptionH4>
        </Container>

        <Container>
          <IconImage src={DecentralizeIcon} />

          <DescriptionH4>
            <Trans>Decentralised & Secure</Trans>
          </DescriptionH4>
        </Container>
      </Slider>
    </Wrapper>
  )
})

export default FeaturesCarousal
