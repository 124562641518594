import React from 'react'
import styled from 'styled-components/macro'
// import { Box } from 'rebass'
import { Trans } from '@lingui/macro'
// import { ThemedText } from 'theme'

// const HeroDescription = styled(Box)`
//   text-align: center;
//   font-family: Poppins;
//   font-size: 20px;
//   font-style: normal;
//   font-weight: 500;
//   max-width: 800px;
//   margin-top: 16px !important;
//   line-height: 24px;
//   @media screen and (max-width: 800px) {
//     margin-top: 10px !important;
//     font-size: 16px;
//   }
// `
// const HeaderText = styled(ThemedText.Black)`
//   text-align: center;
//   font-size: 48px;
//   font-weight: 600 !important;
//   @media screen and (max-width: 800px) {
//     font-size: 32px;
//   }
// `
const Section = styled.section`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 800px) {
    margin-top: 32px;
    // padding: 0px 10px;
    padding: 0px 16px;
  }
`
//
const HeaderTextP = styled.p`
  color: #0f0e0e;
  text-align: center;
  font-size: 48px;
  font-weight: 600 !important;
  @media screen and (max-width: 800px) {
    font-size: 32px;
  }
`
const HeroDescriptionP = styled.p`
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  max-width: 800px;
  margin-top: 16px !important;
  line-height: 24px;
  @media screen and (max-width: 800px) {
    margin-top: 10px !important;
    font-size: 16px;
  }
`
const PartnerDescription = () => {
  return (
    <>
      <Section>
        <HeaderTextP>
          <Trans>What We Need From You</Trans>
        </HeaderTextP>
        <HeroDescriptionP>
          <Trans>
            We&#39;re always looking for the hottest, highest potential projects to showcase in our ecosystem. You can
            send us information about your project and if it looks like it will be interesting for our users we will
            include it in our trending projects space.
          </Trans>
        </HeroDescriptionP>
        <HeroDescriptionP>
          <Trans>
            Please provide us any relevant metrics on any notable milestones achieved to date including community,
            product or financial.
          </Trans>
        </HeroDescriptionP>
      </Section>
    </>
  )
}

export default PartnerDescription
