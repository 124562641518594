import React from 'react'
import BitCoinist from '../../assets/svg/recommended/bitcoinist_logo.svg'
import BitForex from '../../assets/svg/recommended/bitforex_logo.svg'
import CryptoTelegraph from '../../assets/svg/recommended/cryptotelegraph_logo.svg'
import BeInCrypto from '../../assets/svg/recommended/beincrypto_logo.svg'
import CryptoNews from '../../assets/svg/recommended/cryptonews_logo.svg'
import TechoPedia from '../../assets/svg/recommended/techopedia_logo.svg'
import CoinDesk from '../../assets/svg/recommended/coindesk_logo.svg'
import styled from 'styled-components/macro'
import Marquee from 'react-fast-marquee'

const CarouselContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
  @media screen and (width: 1366px) and (height: 768px) {
    margin-top: 0px;
  }
`

const StyledImage = styled.img`
  height: 80px;
  width: 14vw;
  @media (max-width: 800px) {
    width: 24vw;
  }
  @media (max-width: 500px) {
    width: 38vw;
  }
  @media screen and (min-width: 831px) and (max-width: 1024px) {
    width: 18vw;
  }
  @media screen and (min-width: 768px) and (max-width: 830px) {
    width: 20vw;
  }
  @media screen and (width: 1366px) and (height: 768px) {
    height: 60px;
  }
`

const itemStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 3vw',
  boxSizing: 'border-box',
}

const LogoCarousel = React.memo(function LogoCarousel() {
  const logoList = [
    {
      id: 1,
      url: BeInCrypto,
      alt: 'Be in Crypto',
    },
    {
      id: 2,
      url: TechoPedia,
      alt: 'Techo Pedia',
    },
    {
      id: 3,
      url: BitForex,
      alt: 'Bit Forex',
    },
    {
      id: 4,
      url: CoinDesk,
      alt: 'Coin Desk',
    },
    {
      id: 5,
      url: CryptoTelegraph,
      alt: 'Crypto Telegraph',
    },
    {
      id: 6,
      url: BitCoinist,
      alt: 'Bit Coinist',
    },
    {
      id: 7,
      url: CryptoNews,
      alt: 'Crypto News',
    },
  ]

  const sliderSettings = {
    play: true,
    speed: 100,
    loop: 0,
  }
  return (
    <CarouselContainer>
      <Marquee {...sliderSettings}>
        {logoList.map((logoItem) => (
          <div style={itemStyle} key={logoItem.id}>
            <StyledImage src={logoItem.url} alt={logoItem.alt} />
          </div>
        ))}
      </Marquee>
    </CarouselContainer>
  )
})

export default LogoCarousel
