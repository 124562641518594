import { Box } from 'rebass'
import TradeIcon from '../../assets/svg/trading-icon.svg'
import LowFeesIcon from '../../assets/svg/low-fees-icon.svg'
import DecentralizeIcon from '../../assets/svg/decentralized-icon.svg'
import styled from 'styled-components/macro'
// import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'
import { Trans } from '@lingui/macro'

const IconImage = styled.img``
// const Description = styled(ThemedText.DarkGray)`
//   font-weight: 500;
//   font-size: 20px;
//   line-height: 30px;
//   color: #868686;
//   @media screen and (max-width: 600px) {
//     font-size: 18px;
//   }
// `
const Container = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 24px;
  @media screen and (max-width: 850px) {
    display: none;
  }
`
//
const DescriptionH4 = styled.h4`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #868686;
  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
`
const IconSection = () => {
  return (
    <Container flexDirection={isMobile ? 'column' : 'row'}>
      <Box textAlign={'center'}>
        <IconImage src={TradeIcon} />

        <DescriptionH4>
          <Trans>Trading Insights</Trans>
        </DescriptionH4>
      </Box>
      <Box textAlign={'center'}>
        <IconImage src={LowFeesIcon} />

        <DescriptionH4>
          <Trans>Low Fees</Trans>
        </DescriptionH4>
      </Box>
      <Box textAlign={'center'}>
        <IconImage src={DecentralizeIcon} />

        <DescriptionH4>
          <Trans>Decentralised & Secure</Trans>
        </DescriptionH4>
      </Box>
    </Container>
  )
}

export default IconSection
