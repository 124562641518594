import { Box } from 'rebass'
import { ThemedText } from 'theme'
import styled from 'styled-components/macro'
// import MobileApp from '../../assets/svg/mobile_app.svg'
import Silver from '../../assets/svg/Silver.svg'
import DiagonalArrow from '../../assets/svg/diagonal_arrow.svg'
import PlayStore from '../../assets/svg/play_store.svg'
import IOSBanner from '../../assets/svg/app_store.svg'
import { darken } from 'polished'
import { isMobile } from 'utils/userAgent'
import { Trans } from '@lingui/macro'

// const Heading = styled(ThemedText.Main)`
//   font-weight: 700;
//   font-size: 16px;
//   line-height: 24px;
//   letter-spacing: 2px;
//   text-transform: uppercase;
//   color: #75ffff;
// `
const LinkText = styled(ThemedText.White)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 20px;
  color: #75ffff !important;
  cursor: pointer;
  &:hover {
    color: ${darken(0.05, '#75ffff')} !important;
  }
`
const Description = styled(ThemedText.White)`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #868686;
  @media screen and (max-width: 1000px) {
    line-height: 24px;
  }
`
const Container = styled.div`
  margin-top: 108px;
  display: flex;
  flex-direction: row;
  padding: 0px;
  background: linear-gradient(180deg, #5961ff 0%, #b3b7ff 100%);
  border-radius: 60px;
  position: relative;
  align-items: end;
  padding-top: 15px;
  @media screen and (max-width: 1280px) {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    padding-top: 0px;
    margin-top: 40px;
  }
`

const MobileImage = styled.img`
  // padding-top: 40px;
  // height: 615px;
  width: 455px;
  padding: 47.609px 55.844px 0px 69px;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 47.609px 20px 0px 20px;
    width: auto;
  }
  @media screen and (max-width: 500px) {
    padding: 47.609px 20px 0px 20px;
    height: 450px;
    width: auto;
  }
  // position: absolute;
  // bottom: 0px;
`
const MobileImageContent = styled(Box)`
  padding: 70px 30px;
  @media screen and (max-width: 1240px) {
    padding: 32px 16px 48px 16px;
  }
`
const AppLinks = styled(Box)`
  display: flex;
  column-gap: 16px;
  // visibility: hidden;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    & img {
      width: 216px;
      height: 64px;
    }
  }
  @media screen and (max-width: 1280px) {
    justify-content: center;
  }
  // @media screen and (max-width: 600px) {
  //   display: none !important;
  // }
`
const UndecoratedLink = styled.a<{ isMobile: boolean }>`
  text-decoration: none;
  display: flex;
  justify-content: start;
  @media screen and (max-width: 1280px) {
    justify-content: center;
  }
`
const UndecoratedLinkImage = styled.a`
  text-decoration: none;
`
//
const DescriptionTextP = styled.p<{ fontSize: string }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '20px')};
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  @media screen and (max-width: 1000px) {
    line-height: 24px;
  }
`
const HeadingP = styled.p<{ fontSize: string }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
  font-weight: 700;
  line-height: ${isMobile ? 'normal' : '24px'};
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #75ffff;
`
const ThemedTextWhiteH2 = styled.h2<{ fontSize: string }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '48px')};
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: ${isMobile ? 'normal' : '58px'};
  margin: 10px 0px 20px 0px;
  text-transform: none;
  @media screen and (max-width: 1000px) {
    margin: 10px 0px 10px 0px;
  }
`

export default function MobileAppSection() {
  return (
    <Container>
      {/* <Box padding={'40px 0px 0px 0px'}> */}
      <MobileImage src={Silver} alt="mobile app"></MobileImage>
      {/* </Box> */}
      <MobileImageContent>
        <HeadingP fontSize={isMobile ? '12px' : '16px'}>
          <Trans>INTRODUCING</Trans>
        </HeadingP>

        <ThemedTextWhiteH2 fontSize={isMobile ? '24px' : '48px'}>{'Best Wallet'}</ThemedTextWhiteH2>

        <Description>
          <DescriptionTextP fontSize={isMobile ? '15px' : '20px'}>
            <Trans>The smartest way to manage your digital assets.</Trans>
          </DescriptionTextP>
        </Description>
        <Description marginTop={'20px'}>
          <DescriptionTextP fontSize={isMobile ? '15px' : '20px'}>
            <Trans>
              Best Wallet is the fully non-custodial crypto wallet at the heart of the Best Web3 Ecosystem. Buy, store,
              send, and receive Bitcoin and many other cryptocurrencies safely and securely with the Best Wallet app.
            </Trans>
          </DescriptionTextP>
        </Description>
        <Description marginTop={'20px'}>
          <DescriptionTextP fontSize={isMobile ? '15px' : '20px'}>
            <Trans>
              Portfolio Management and Token Analytics give users everything they need to get in early on the hottest
              token launches, make the right trades at the right times, and maximise profits.
            </Trans>
          </DescriptionTextP>
        </Description>
        <UndecoratedLink isMobile={isMobile} href="https://bestwallet.com" target="_blank">
          <LinkText marginTop={'20px'} fontSize={isMobile ? '12px' : '16px'} fontWeight={700}>
            <Trans>Discover Best Wallet, non custodial wallet</Trans>
            <img width={'13px'} src={DiagonalArrow} alt="arrow"></img>
          </LinkText>
        </UndecoratedLink>
        <AppLinks marginTop={'32px'} display={'flex'}>
          <Box>
            <UndecoratedLinkImage href="https://apps.apple.com/gb/app/best-wallet/id6451312105" target="_blank">
              <img width={!isMobile ? '226' : '216'} height={'64'} src={IOSBanner} alt={'test flight badge'}></img>
            </UndecoratedLinkImage>
          </Box>
          <Box>
            <UndecoratedLinkImage
              href="https://play.google.com/store/apps/details?id=com.bestwallet.mobile"
              target="_blank"
            >
              <img width={!isMobile ? '226' : '216'} height={'64'} src={PlayStore} alt={'play store'}></img>
            </UndecoratedLinkImage>
          </Box>
        </AppLinks>
      </MobileImageContent>
    </Container>
  )
}
