import React from 'react'
import { Box } from 'rebass'
import styled from 'styled-components/macro'
import ListNumber from './ListItem'
import { Trans } from '@lingui/macro'
import { isMobile } from 'utils/userAgent'
import IOSBanner from '../../assets/svg/Available_ios.svg'
import AndriodBanner from '../../assets/svg/Available_andriod.svg'
import PointList from './PointList'
// import { ThemedText } from 'theme'
const DescriptionContainer = styled.span<{ isMobile: boolean }>`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: ${({ isMobile }) => (isMobile ? '68px 16px 0px 16px' : '48px 16px 0px 16px')};
  font-size: ${({ isMobile }) => (isMobile ? '32px' : '48px')};
  width: ${({ isMobile }) => (isMobile ? '100%' : '629px')};
`
const ListItems = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`
const ListItem = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
`
const BannerHolder = styled.div`
  display: flex;
  height: 52px;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-right: 80px;
  @media screen and (max-width: 600px) {
    height: 104px;
    margin-left: 80px;
  }
`
const ImageContainer = styled(Box)`
  height: 52px;
  @media screen and (max-width: 600px) {
    height: 52px;
  }
`
const UndecoratedLinkImage = styled.a`
  text-decoration: none;
`
const ThemedTextBlackP = styled.p<{ fontSize: string }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '48px')};
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 68px;
`
function Description() {
  return (
    <DescriptionContainer isMobile={isMobile}>
      <ThemedTextBlackP fontSize={isMobile ? '32px' : '48px'}>
        <Trans>How to get started</Trans>
      </ThemedTextBlackP>
      <ListItems>
        <ListItem>
          <ListNumber number="1" content={<Trans>Download the Best Wallet app</Trans>}></ListNumber>
        </ListItem>
        <BannerHolder>
          <ImageContainer>
            <UndecoratedLinkImage href="https://apps.apple.com/gb/app/best-wallet/id6451312105" target="_blank">
              <img height={45} src={IOSBanner} alt={'IOS'}></img>
            </UndecoratedLinkImage>
          </ImageContainer>
          <ImageContainer>
            <UndecoratedLinkImage
              href="https://play.google.com/store/apps/details?id=com.bestwallet.mobile"
              target="_blank"
            >
              <img width={'150'} height={45} src={AndriodBanner} alt={'Andriod'}></img>
            </UndecoratedLinkImage>
          </ImageContainer>
        </BannerHolder>
      </ListItems>
      <ListItems>
        <ListItem>
          <ListNumber number="2" content={<Trans>Create a wallet in the BEST Wallet</Trans>}></ListNumber>
        </ListItem>
      </ListItems>
      <ListItems>
        <ListItem>
          <ListNumber
            number="3"
            content={
              <Trans>
                Make sure you complete all the steps below to ensure we can track your activities towards the BEST
                airdrop
              </Trans>
            }
          ></ListNumber>
        </ListItem>
        <PointList></PointList>
      </ListItems>
    </DescriptionContainer>
  )
}
export default Description
