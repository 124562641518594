import React from 'react'
import styled from 'styled-components/macro'
interface TextParagraphProps {
  fontWeight: number
  color: string
  children: any
}
const Paragraph = styled.p<{ fontWeight: number; color: string }>`
  font-size: 20px;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  @media (min-width: 1024px) and (max-width: 1399px) {
    font-size: 18px;
  }
  // @media screen and (max-width: 1000px) {
  //   // font-size: 15px;
  //   text-align: center;
  // }
  // @media screen and (min-width: 768px) and (max-width: 1024px) {
  //   font-size: 35px;
  //   line-height: normal;
  // }
  @media screen and (max-width: 600px) {
    text-align: center;
    font-size: 18px;
  }
  @media screen and (max-width: 350px) {
    text-align: center;
    // font-size: 10px;
  }
`
const ParagraphText = ({ children, fontWeight, color, ...props }: TextParagraphProps) => {
  return (
    <Paragraph fontWeight={fontWeight} color={color} {...props}>
      {children}
    </Paragraph>
  )
}
export default ParagraphText
