import { Trans } from '@lingui/macro'
// import Button from 'components/@common/button'
// import { AutoColumn } from 'components/Column'
// import Modal from 'components/Modal'
// import { RowBetween } from 'components/Row'
import { FC, useEffect, useState } from 'react'
import { Box } from 'rebass'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
// import { detectIncognito } from 'utils/detectIncognito'
import { isMobile } from 'utils/userAgent'
// import { X } from 'react-feather'

const ListContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 46px;
  padding: 10px 10px;
  gap: 11px;
  max-width: 550px;
  font-family: inter;
  letter-spacing: -0.86px;
  width: 100%;
  @media screen and (max-width: 600px) {
    margin-top: 24px;
    padding: 0px;
  }
`
const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #757dff;
  padding: 8px 18px;
  border-radius: 26px;
`
const GreyPointer = styled.div`
  width: 29px;
  height: 29px;
  min-width: 29px;
  border-radius: 50%;
  background-color: #efefff;
`
const PointContainer = styled.div`
  padding: 2px 19px;
  background-color: #42c248;
  border-radius: 15px;
  height: 30px;
  border: 2px solid #fff;
  box-shadow: 0px 4px 4px 0px rgba(34, 56, 87, 0.22);
`
// const HoverText = styled.div`
//   text-decoration: none;
//   color: ${({ theme }) => theme.text1};
//   display: flex;
//   align-items: center;

//   :hover {
//     cursor: pointer;
//   }
// `
export interface PointItemProps {
  label: string
  points: string
}
export const PointItem: FC<PointItemProps> = ({ label, points }: PointItemProps) => {
  return (
    <ItemContainer>
      <Box display={'flex'} alignItems={'center'} marginRight={'10px'}>
        <GreyPointer></GreyPointer>
        <ThemedText.White letterSpacing={'-0.86px'} marginLeft={'14px'} fontSize={'16px'}>
          <Trans>{label}</Trans>
        </ThemedText.White>
      </Box>
      <PointContainer>
        {
          <ThemedText.White fontSize={'16px'}>
            <Trans>{points}</Trans>
          </ThemedText.White>
        }
      </PointContainer>
    </ItemContainer>
  )
}

const PointList: FC = () => {
  // const pointList = [
  //   { id: 'airdrop_point_1', label: 'Download Our App', points: '+1' },
  //   { id: 'airdrop_point_2', label: 'Engage On Our Platform', points: '+1' },
  //   { id: 'airdrop_point_3', label: 'Visit Our Website', points: '+1' },
  //   { id: 'airdrop_point_4', label: 'Join @BestWallet_Official on Telegram', points: '+1' },
  //   { id: 'airdrop_point_5', label: 'Join Our Discord Server', points: '+1' },
  //   { id: 'airdrop_point_6', label: 'Follow @BestWallet__ on Twitter', points: '+1' },
  //   { id: 'airdrop_point_7', label: 'Visit @bestwalletofficial on Instagram', points: '+1' },
  // ]
  const FORM_ID = '7c92e3dea64ecb343752344d4e5aef7f'
  const [formSubmitted, setFormSubmitted] = useState(false)

  // const [isIncognito, setIsIncognito] = useState(false)

  // const [isEnabledModal, setEnabledModal] = useState(false)

  // useEffect(() => {
  //   try {
  //     detectIncognito().then((result) => {
  //       setIsIncognito(result.isPrivate)
  //     })
  //   } catch (e) {
  //     console.log('failed to determine browser mode', e)
  //     console.log('failed to determine browser mode', e)
  //   }
  // }, [])

  // const togglePopup = () => {
  //   setIsIncognito(false)
  // }

  // const parseMessage = (data: any, callback: any) => {
  //   console.log('Inside parseMessage', data)
  //   try {
  //     const formMessage = JSON.parse(data)
  //     if (formMessage?.formId && formMessage?.formId === FORM_ID) {
  //       setFormSubmitted(formMessage.submitted || false)
  //       console.log('formMessage', formMessage)
  //       if (callback && typeof callback === 'function') {
  //         console.log('callback')
  //         callback(formMessage)
  //       }
  //     }
  //     setEnabledModal(!!formMessage?.formId)
  //   } catch (e) {
  //     console.log('failed to read iframe message', e)
  //   }
  // }

  // useEffect(() => {
  //   function receiveMessage(event: MessageEvent) {
  //     if (typeof event?.data === 'string') {
  //       console.log('Inside receiveMessage', event.data)
  //       parseMessage(event?.data, function (formMessage: any) {
  //         // Execute your callback function based on the condition
  //         if (formMessage.submitted) {
  //           setFormSubmitted(formMessage.submitted || false)
  //         }
  //       })
  //     }
  //   }

  //   receiveMessage(new MessageEvent('message')) // Call the function directly with a dummy event

  //   return () => {
  //     // No need to remove the event listener since it's not added
  //   }
  // }, [])

  useEffect(() => {
    const receiveMessage = function (event: any) {
      if (typeof event?.data === 'string') {
        try {
          const formMessage = JSON.parse(event?.data)
          if (formMessage?.formId && formMessage?.formId === FORM_ID) {
            if (formMessage.submitted) {
              setFormSubmitted(true)
            } else {
              setFormSubmitted(false)
            }
            console.log('formMessage', formMessage)
          }
          // if (formMessage?.formId) {
          //   setEnabledModal(true)
          // } else {
          //   setEnabledModal(false)
          // }
        } catch (e) {
          console.log('failed to read iframe message', e)
        }
      }
    }
    window.addEventListener('message', receiveMessage, false)
    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [])

  return (
    <>
      <ListContainer>
        <iframe
          height={formSubmitted ? (!isMobile ? '550px' : '575px') : !isMobile ? '715px' : '760px'}
          src={'https://dashboard.withblaze.app/forms/' + FORM_ID}
          title="description"
        ></iframe>
      </ListContainer>
      {/* <Modal
        isOpen={isIncognito ? (isIncognito && isEnabledModal ? false : true) : false}
        onDismiss={() => togglePopup()}
      >
        <AutoColumn gap="12px">
          <RowBetween padding="1rem 1rem 0.5rem 1rem">
            <ThemedText.MediumHeader>
              <Trans>Important Notice</Trans>
            </ThemedText.MediumHeader>
            <HoverText onClick={() => togglePopup()}>
              <X size={24} />
            </HoverText>
          </RowBetween>
          <RowBetween padding="1rem 1rem 0.5rem 1rem">
            <ThemedText.DarkGray>
              <Trans>
                {' '}
                To access the Airdrop page, either switch your browser from incognito mode to normal mode or enable
                third party cookies in your browser.
              </Trans>
            </ThemedText.DarkGray>
          </RowBetween>
          <Box padding="1rem 1rem 0.5rem 1rem" display={'flex'} justifyContent={'end'}>
            <Button onClick={() => togglePopup()} borderRadius={'12px'}>
              <ThemedText.White>
                <Trans>close</Trans>
              </ThemedText.White>
            </Button>
          </Box>
        </AutoColumn>
      </Modal> */}
    </>
  )
}

export default PointList
