import { RouteComponentProps } from 'react-router-dom'
import { Box } from 'rebass'
import styled from 'styled-components/macro'
// import { ThemedText } from 'theme'
import QnASection from './QnASection'
import Lottie from 'react-lottie'

import ContentImageSection from './ContentImageSection'
import MobileAppSection from './MobileAppSection'
import ContentImageCard from './ContentImageCard'
import tradeAnything from '../../assets/svg/home-sections/trade_anything.svg'
// import earlyAccess from '../../assets/svg/early_access.svg'
import AirDrop from '../../assets/svg/home-sections/best_airdrop.svg'
import Insights from '../../assets/svg/home-sections/market_insights.svg'
import Security from '../../assets/svg/home-sections/token_security.svg'
import Sentiment from '../../assets/svg/home-sections/token_sentiment.svg'
import TokenInfo from '../../assets/svg/home-sections/token_infos.svg'
import Trending from '../../assets/svg/home-sections/trending_tokens.svg'
import Button from 'components/@common/button'
import animationData from '../../assets/json/Best DEX 2.json'
import { isMobile } from 'utils/userAgent'
import IconSection from './IconSection'
import { Trans } from '@lingui/macro'
// eslint-disable-next-line no-restricted-imports
import { t } from '@lingui/macro'
import Footer from 'components/Footer/Footer'
import FeaturesCarousal from './FeaturesCarousal'
import ParagraphText from 'components/ParagraphText'
import { useLingui } from '@lingui/react'
import LogoCarousel from './LogoCarousel'
import { useEffect, useState } from 'react'
import Modal from 'components/Modal'
import { ButtonSecondary } from 'components/Button'
import { CustomColumn } from 'components/Column'
import { X } from 'react-feather'
import { ButtonText, ThemedText } from 'theme/components'
import bannerText from 'components/UKDisclaimer/bannerText'

const Container = styled.div`
  padding: 5px;
  margin: 0px 0px 0px 7px;
  // margin: 0px 120px;
  // max-width: 1224px;
  max-width: 1273px;
  @media screen and (max-width: 1480px) {
    // padding: 0px 50px;
    margin: 0px 80px;
  }
  @media screen and (max-width: 1150px) {
    margin: 0px 30px;
  }
  @media screen and (max-width: 1060px) {
    margin: 0px 50px;
  }
  @media screen and (max-width: 600px) {
    margin: 0px 10px;
  }
`
const HeroWrapper = styled.div`
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 767px) {
    height: auto;
  }
`
const HeroBannerWrapper = styled.div`
  width: 100%;
  height: 80vh;
  padding: 0px;
  @media screen and (max-width: 767px) {
    height: 100vh;
  }
`
const CarousalWrapper = styled.div`
  width: 100%;
  height: 20vh;
  @media screen and (max-width: 767px) {
    height: auto;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
const HeroBanner = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  background: linear-gradient(180deg, #5860ff 37.64%, #fff 203.72%);
  height: inherit;
  width: 100%;
  padding: 0px 110px 60px 75px;

  @media screen and (min-width: 1151px) and (max-width: 1440px) {
    padding: 0px 85px 60px 85px;
  }
  @media screen and (min-width: 1061px) and (max-width: 1150px) {
    padding: 0px 35px 60px 35px;
  }
  @media screen and (min-width: 1025px) and (max-width: 1060px) {
    padding: 0px 55px 60px 55px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 0px 45px 60px 45px;
  }

  @media screen and (max-width: 600px) {
    align-items: center;
    padding: 0;
  }
`

const HeroInner = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 83px !important;
  max-width: 1224px;
  height: inherit;
  @media screen and (max-width: 1440px) {
    max-width: 1273px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    max-width: 70%;
  }
  @media screen and (max-width: 600px) {
    justify-content: flex-end;
    margin-top: 83px !important;
    max-width: 90%;
  }
`

const HeroContent = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    flex-basis: 50%;
  }

  // @media only screen and (min-width: 375px) {
  //   // justify-content: center;
  //   // align-items: center;
  // }
  // @media only screen and (max-width: 1000px) {
  //   // justify-content: center;
  //   // align-items: center;
  // }
`
// const ResponsiveTextTheme = styled(ThemedText.White)`
// @media screen and (max-width: 1000px) {
// text-align: center;
// }
// `
// const ResponsiveDarkTextTheme = styled(ThemedText.Black)`
// display: flex;
// justify-content: center;
// @media screen and (max-width: 1000px) {
// text-align: center;
// }
// `
const ResponsiveBox = styled(Box)`
  display: flex;
  margin-top: 32px !important;
  // @media only screen and (min-width: 768px) and (max-width: 1024px) {
  //   justify-content: center;
  // }
  @media screen and (max-width: 767px) {
    align-items: center;
    flex-direction: column;
    margin-top: 20px !important;
  }
`

const CardContainer = styled.div`
  max-width: 1280px;
  padding: 0px 10px;
  margin: 0px 90px;
  // margin: 0px 130px 0px 90px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 8%;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0;
    padding: 0px;
  }
  @media screen and (min-width: 801px) and (max-width: 1000px) {
    gap: 0;
    padding: 0px;
  }
  @media screen and (max-width: 1150px) {
    margin: 0px 30px;
  }
  @media screen and (max-width: 1060px) {
    margin: 0px 50px;
  }
  @media screen and (max-width: 600px) {
    margin: 0px 10px;
  }
`
const ScrollTrigger = styled.span`
  position: relative;
  top: -50px;
  @media screen and (max-width: 600px) {
    top: 100px;
  }
`
//
// const ResponsiveTextTheme1 = styled.p<{ fontSize: string }>`
//   font-size: ${({ fontSize }) => (fontSize ? fontSize : '20px')};
//   color: #fff;
//   font-weight: 500;
//   @media screen and (max-width: 1000px) {
//     text-align: center;
//   }
// `
const ResponsiveDarkTextTheme1 = styled.p<{ fontSize: string }>`
  display: flex;
  justify-content: center;
  text-align: center;
  fontweight: 600;
  color: #404040;
  font-weight: 600;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '20px')};
  // margin-bottom: ${isMobile ? '10px' : '0px'};
  @media screen and (max-width: 1000px) {
    text-align: center;
  }
`
const FAQTextP = styled.p<{ fontSize: string }>`
  color: #868686;
  font-family: Poppins;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '20px')};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
`
// const HeadingTextH1 = styled.h1<{ fontWeight: number; color: string }>`
//   font-size: 72px;
//   margin: 0;
//   font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 700)};
//   color: ${({ color }) => (color ? color : '#fff')};
//   line-height: 82px;
//   display: -webkit-box;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
//   overflow: hidden;

//   @media screen and (height: 725px) {
//     font-size: 53px;
//     line-height: normal;
//   }
//   @media screen and (height: 900px) {
//     font-size: 53px;
//     line-height: normal;
//   }

//   @media (min-width: 1024px) and (max-width: 1399px) {
//     font-size: 45px;
//     line-height: normal;
//   }

//   @media screen and (max-width: 1000px) {
//     font-size: 25px;
//     text-align: center;
//     line-height: normal;
//   }

//   @media screen and (max-width: 765px) {
//     font-size: 35px;
//     text-align: center;
//     line-height: normal;
//   }

//   @media screen and (max-width: 576px) {
//     font-size: 30px;
//     text-align: center;
//     line-height: normal;
//   }

//   @media screen and (max-width: 375px) {
//     font-size: 25px;
//     text-align: center;
//     line-height: normal;
//   }

//   @media screen and (max-width: 320px) {
//     font-size: 12px;
//     text-align: center;
//     line-height: normal;
//     // padding: 0px;
//   }

//   //   @media only screen and (width: 428px) and (height: 926px) {
//   //     // and (-webkit-device-pixel-ratio: 3)
//   //     -webkit-color: red;
//   //     font-size: 25px;
//   //     text-align: center;
//   //     line-height: normal;
//   //     padding: 0px 7%;
//   //   }
//   //
// `

const UndecoratedLink = styled.a`
  text-decoration: none;
  display: flex;
  @media only screen and (max-width: 767px) {
    justify-content: center;
  }
`
const ParagraphTextDiv = styled.div`
  margin-top: 32px;
  @media only screen and (max-width: 725px) {
    margin-top: 16px;
  }
`
const HeadingTextH1 = styled.h1<{ fontWeight: number; color: string }>`
  font-size: 72px;
  margin: 0;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 700)};
  color: ${({ color }) => (color ? color : '#fff')};
  line-height: ${!isMobile ? '82px' : 'normal'};

  @media (min-width: 1024px) and (max-width: 1399px) {
    font-size: 38px;
    line-height: normal;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: 48px;
    line-height: normal;
  }

  @media screen and (width: 1024px) and (height: 1366px) {
    font-size: 48px;
    line-height: normal;
  }

  @media screen and (max-width: 767px) {
    font-size: 35px;
    text-align: center;
    line-height: normal;
  }

  @media screen and (max-width: 375px) {
    font-size: 30px;
    text-align: center;
    line-height: normal;
  }

  @media screen and (max-width: 320px) {
    font-size: 12px;
    text-align: center;
    line-height: normal;
    // padding: 0px;
  }
`

const HeadingLangTextH1 = styled.h1<{ fontWeight: number; color: string }>`
  font-size: 72px;
  margin: 0;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 700)};
  color: ${({ color }) => (color ? color : '#fff')};
  line-height: ${!isMobile ? '82px' : 'normal'};

  @media (min-width: 1024px) and (max-width: 1399px) {
    font-size: 38px;
    line-height: normal;
  }

  @media (width: 1440px) {
    font-size: 55px;
    line-height: normal;
  }

  // @media screen and (max-width: 1000px) {
  //   font-size: 35px;
  //   text-align: center;
  //   line-height: normal;
  // }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: 48px;
    line-height: normal;
  }
  @media screen and (width: 1024px) and (height: 1366px) {
    font-size: 48px;
    line-height: normal;
  }
  @media screen and (max-width: 767px) {
    font-size: 35px;
    text-align: center;
    line-height: normal;
  }

  @media screen and (max-width: 375px) {
    font-size: 30px;
    text-align: center;
    line-height: normal;
  }

  @media screen and (max-width: 320px) {
    font-size: 12px;
    text-align: center;
    line-height: normal;
    // padding: 0px;
  }
`
const LottieBox = styled(Box)`
  display: flex;
  flex-basis: 50%;
  // flex-basis: ${!isMobile ? '50%' : '40%'};
  justify-content: ${!isMobile ? 'end' : 'flex-end'};
  align-items: ${!isMobile ? 'center' : 'flex-end'};
  // marginx: ${isMobile ? '-5%' : '0'};
  // width: ${isMobile ? '75%' : '100%'};
  // height: ${isMobile ? '75%' : '100%'};
  @media only screen and (max-width: 480px) {
    width: -webkit-fill-available;
    flex-basis: 0;
  }
`

// const CarouselDiv = styled.div`
// width: 100%;
// // margin-top: 10px;
// display: flex;
// align-items: flex-end;
// @media screen and (width: 1440px) {
//   // margin-top: 20px;
// }
// `
const DecentraizePText = styled.p`
  font-family: Poppins;
  font-size: ${isMobile ? '15px' : '20px'};
  color: #868686;
  font-weight: 400;
  line-height: 30px;
`

// Modal css
const Wrapper = styled(CustomColumn)`
  padding: 8px;
`

const ButtonContainer = styled(CustomColumn)`
  padding: 8px 12px 4px;
`

const CloseIconWrapper = styled(ButtonText)`
  display: flex;
  color: ${({ theme }) => theme.text2};
  justify-content: flex-end;
  padding: 8px 0px 4px;

  :focus {
    text-decoration: none;
  }
`
export default function Home({ history }: RouteComponentProps) {
  const [country, setCountry] = useState('')
  const [showModal, setShowModal] = useState(false)
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  // // get localstorage data
  // const userData = localStorage.getItem('redux_localstorage_simple_user')
  // let LocalLanguage = 'en-US'
  // if (userData && typeof userData === 'string') {
  //   const getLanguage = JSON.parse(userData) || ''
  //   LocalLanguage = getLanguage?.userLocale ?? ''
  //   console.log(123, LocalLanguage)
  // }

  // get language
  const { i18n } = useLingui()
  const LocalLanguage = i18n._locale

  // get GeoTargetly
  // @ts-ignore
  window.geotargetly_loaded = function geotargetly_loaded() {
    try {
      // @ts-ignore
      const country_name = window?.geotargetly_country_name()
      setCountry(country_name)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (country === 'United Kingdom') {
      setShowModal(true)
    } else {
      console.log('Not a UK')
    }
  }, [country])
  //

  return (
    <>
      <HeroWrapper>
        <HeroBannerWrapper>
          <HeroBanner>
            <HeroInner width={'100%'}>
              {/* Hero text section marginTop={'196px'} */}
              <HeroContent>
                <Box>
                  {LocalLanguage === 'en-US' ? (
                    <>
                      <HeadingTextH1 fontWeight={!isMobile ? 700 : 600} color={'#fff'}>
                        <Trans>Buy and Sell</Trans>
                        <br />
                        <Trans>Instantly on</Trans>
                      </HeadingTextH1>
                      <HeadingTextH1 fontWeight={!isMobile ? 700 : 600} color={'#75FFFF'}>
                        Best DEX
                      </HeadingTextH1>
                    </>
                  ) : (
                    <>
                      <HeadingLangTextH1 fontWeight={!isMobile ? 700 : 600} color={'#fff'}>
                        <Trans>Buy and Sell</Trans>
                        <br />
                        <Trans>Instantly on</Trans>
                      </HeadingLangTextH1>
                      <HeadingLangTextH1 fontWeight={!isMobile ? 700 : 600} color={'#75FFFF'}>
                        Best DEX
                      </HeadingLangTextH1>
                    </>
                  )}
                </Box>

                <ParagraphTextDiv>
                  <ParagraphText color={'#fff'} fontWeight={500}>
                    <Trans>No registration needed. Thousands of tokens to trade at your fingertips.</Trans>
                  </ParagraphText>
                </ParagraphTextDiv>

                {/* <Box marginTop={'32px'}> */}
                {/* <ResponsiveTextTheme fontSize={isMobile ? '18px' : '20px'}>
                <Trans>No registration needed. Thousands of tokens to trade at your fingertips.</Trans>
              </ResponsiveTextTheme> */}
                {/* <ResponsiveTextTheme1 fontSize={isMobile ? '18px' : '20px'}>
                <Trans>No registration needed. Thousands of tokens to trade at your fingertips.</Trans>
              </ResponsiveTextTheme1> */}
                {/* </Box> */}
                <ResponsiveBox marginTop={!isMobile ? '32px' : '20px'}>
                  <UndecoratedLink href={'/app/'}>
                    <Button
                      fontColor={'#4B53D5'}
                      size={!isMobile ? 'XLARGE' : 'MEDIUM'}
                      borderRadius={'10px'}
                      type={'HERO'}
                      text={<Trans>{'Trade Now'}</Trans>}
                      fontWeight={600}
                      // onClick={() => history.push('/app/')}
                    ></Button>
                  </UndecoratedLink>
                </ResponsiveBox>
              </HeroContent>
              {/* Hero Image section */}
              {/* <Box
                display={'flex'}
                justifyContent={!isMobile ? 'end' : 'center'}
                alignItems={'center'}
                flexBasis={'50%'}
                backgroundColor={'gray'}
              > */}
              <LottieBox>
                <Lottie
                  options={defaultOptions}
                  height={!isMobile ? '100%' : '75%'}
                  width={!isMobile ? '100%' : '75%'}
                />
              </LottieBox>
              {/* </Box> */}
            </HeroInner>
          </HeroBanner>
        </HeroBannerWrapper>

        <CarousalWrapper>
          <Box width={'100%'} marginTop={'20px'}>
            <ResponsiveDarkTextTheme1 fontSize={'20px'}>
              <Trans>Recommended By</Trans>
            </ResponsiveDarkTextTheme1>
          </Box>

          <LogoCarousel />

          {/* <CarouselDiv> */}
          {/* <HeroLogoCarousel /> */}
          {/* </CarouselDiv> */}

          {/* <Box width={'100%'} marginTop={'10px'} display={'flex'} alignItems={'flex-end'}>
            {/* <HeroCarousal></HeroCarousal> 
            <HeroLogoCarousel />
          </Box> */}
        </CarousalWrapper>
      </HeroWrapper>

      <Box
        width={'100%'}
        paddingY={isMobile ? '0px' : '30px'}
        marginTop={isMobile ? '5px' : '10px'}
        maxWidth={'1440px'}
      >
        {<IconSection></IconSection>}
      </Box>
      <ScrollTrigger id="features-scroll"></ScrollTrigger>
      <Box width={'100%'} display={'flex'} justifyContent={'center'} maxWidth={'1440px'}>
        <Box width={'80%'} flexDirection={'column'} alignItems="center">
          <FeaturesCarousal />
        </Box>
      </Box>
      <Box overflow={'hidden'} width={'100%'} maxWidth={'1440px'}>
        <ContentImageSection
          imageSrc={tradeAnything}
          heading={<Trans>Decentralised Exchange</Trans>}
          subtitle={
            <>
              <Trans>Trade anything.</Trans> <br />
              <Trans>No registration.</Trans> <br />
              <Trans>No hassle.</Trans>
            </>
          }
          imagePosition={'RIGHT'}
        >
          <Box>
            <Box marginBottom={'32px'}>
              <DecentraizePText>
                <Trans>
                  Trade any token on Ethereum or BNB Smart Chain in seconds, just by connecting your wallet.
                </Trans>
              </DecentraizePText>
            </Box>
            {/* <DummyButtonContainer>
              <DummyButton padding={'10px'}>
                <ThemedText.White fontWeight={'700'} fontSize={'18px'}>
                  <Trans>Coming Soon</Trans>
                </ThemedText.White>
              </DummyButton>
            </DummyButtonContainer> */}
            <UndecoratedLink href={'/app/'}>
              <Button size={'LARGE'} borderRadius={'10px'} text={<Trans>{'Trade Now'}</Trans>}></Button>
            </UndecoratedLink>
          </Box>
        </ContentImageSection>
        <ContentImageSection
          heading={<Trans>Coming Soon</Trans>}
          subtitle={<Trans>Market Insights</Trans>}
          imageSrc={Insights}
          textAboveInMobile
          imagePosition={'LEFT'}
        >
          <Trans>
            Best DEX will have all the tools you need to navigate around crypto trading including real-time market
            updates and advanced charting via Trading View.
          </Trans>
        </ContentImageSection>
        <ContentImageSection
          heading={<Trans>Coming Soon</Trans>}
          subtitle={<Trans>Token Analytics</Trans>}
          textAboveInMobile
          imageSrc={Trending}
          imagePosition={'RIGHT'}
        >
          <Trans>
            From trending tokens to token security, Best Dex token analytics provides traders with in-depth, real-time
            data to execute the right trades.
          </Trans>
        </ContentImageSection>
        <>{/*********** card section start ************/}</>
        {/* <>{`Everything a trader expects. Best DEX will show you quick-glance summaries of a product’s trading statistics - market cap, volume traded, total liquidity, total supply, no. of holders, and total transactions.`}</> */}
        {/* <>{`See what’s safe before you buy through token audits and integrations with GoPlus, Quick Intel, and Token Sniffer.`}</> */}
        {/* <Container> */}
        <CardContainer>
          <ContentImageCard
            heading={<Trans>{'Coming Soon'}</Trans>}
            subtitle={<Trans>{'Token Info'}</Trans>}
            imageSrc={TokenInfo}
            // imagePosition={'LEFT'}
          ></ContentImageCard>
          <ContentImageCard
            heading={<Trans>{'Coming Soon'}</Trans>}
            subtitle={<Trans>{'Token Sentiment'}</Trans>}
            imageSrc={Sentiment}
            // imagePosition={'LEFT'}
          ></ContentImageCard>
          <ContentImageCard
            heading={<Trans>{'Coming Soon'}</Trans>}
            subtitle={<Trans>{'Token Security'}</Trans>}
            imageSrc={Security}
            // imagePosition={'RIGHT'}
          ></ContentImageCard>
        </CardContainer>
        {/* </Container> */}
        <>{/*********** card section end ************/}</>
        <ContentImageSection
          heading={<Trans>Coming Soon</Trans>}
          subtitle={<Trans>BEST Airdrop</Trans>}
          imageSrc={AirDrop}
          imagePosition={'RIGHT'}
          textAboveInMobile
        >
          <>
            <Trans>
              The BEST token airdrop will reward brand advocates and Best Wallet and Best DEX power users over the
              coming months. Holders of the BEST token will receive exclusive benefits on Best DEX including lower cost
              trades
            </Trans>
            {!isMobile && <div id={'mobile-app-section'}></div>}
          </>
        </ContentImageSection>
      </Box>
      <Container>
        <Box width={'100%'}>
          {isMobile && <div id={'mobile-app-section'}></div>}
          <MobileAppSection></MobileAppSection>
        </Box>
        <Box width={'100%'}>
          <QnASection
            imageSrc={tradeAnything}
            imageAlt={t`Frequently Asked`}
            heading={<Trans>Frequently Asked</Trans>}
            subtitle={<Trans>Questions</Trans>}
            imagePosition={'RIGHT'}
          >
            <Box>
              <Box marginBottom={'32px'}>
                <FAQTextP fontSize={isMobile ? '15px' : '20px'}>
                  <Trans>Find answers to the most frequently asked questions about Best Dex right here.</Trans>
                </FAQTextP>
              </Box>
              <Box marginBottom={'32px'}>
                <FAQTextP fontSize={isMobile ? '15px' : '20px'}>
                  <Trans>
                    If the answer you’re looking for isn’t here, email contact@bestwallet.com and we’ll assist you.
                  </Trans>
                </FAQTextP>
              </Box>
            </Box>
          </QnASection>
        </Box>
      </Container>
      <Footer />
      {/* Start Disclaimer Modal */}
      <Modal isOpen={showModal} onDismiss={() => setShowModal(false)}>
        <Wrapper gap="md">
          <CloseIconWrapper onClick={() => setShowModal(false)}>
            <X size={24} />
          </CloseIconWrapper>
          <CustomColumn gap="sm">
            <ThemedText.HeadlineLarge padding="0px 8px" fontSize="24px" lineHeight="32px">
              <Trans>Disclaimer for UK residents</Trans>
            </ThemedText.HeadlineLarge>
            <ThemedText.BodyPrimary padding="8px 8px 12px" lineHeight="24px">
              {bannerText}
            </ThemedText.BodyPrimary>
          </CustomColumn>
          <ButtonContainer gap="md">
            <ButtonSecondary
              onClick={() => setShowModal(false)}
              padding={'16px 10px'}
              style={{ margin: '4px 0 0 0', color: '#000000', fontWeight: 600 }}
            >
              <Trans>Dismiss</Trans>
            </ButtonSecondary>
          </ButtonContainer>
        </Wrapper>
      </Modal>
      {/* Start Disclaimer Modal */}
    </>
  )
}
