import { Box } from 'rebass'
import { useRef } from 'react'

import useOnScrollVisibility from 'hooks/onScrollVisibility'
// import { ThemedText } from 'theme'
import styled from 'styled-components/macro'
import { isMobile } from 'utils/userAgent'
import { ReactElement } from 'react'
// eslint-disable-next-line no-restricted-imports
import { t } from '@lingui/macro'

const Container = styled(Box)<{ show?: boolean }>`
  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.17, 0.67, 0.52, 0.98);
  opacity: ${({ theme, show }) => (show ? 1 : 0)};
  transform: ${({ theme, show }) => (show ? 'translateY(0px)' : 'translateY(200px)')};
  gap: 10px;
  margin-top: 108px !important;
  @media screen and (max-width: 1000px) {
    align-items: center;
    gap: 10px;
    text-align: center;
    padding: 0px 25px;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 0px !important;
    margin-top: 108px !important;
  }
  @media screen and (max-width: 767px) {
    padding: 0px 5px;
    margin-top: 40px !important;
  }
`
const AnimationRefBox = styled.div`
  position: relative;
  top: 400px;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 1000px) {
    width: 90%;
    padding: 0px;
  }
`
const ImageContainer = styled(Box)`
  @media screen and (max-width: 1000px) {
    width: 90%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

// const SubtitleFRLang = styled(ThemedText.Black)`
//   height: 100px;
//   font-size: 35px;
//   text-align: center;
//   @media screen and (max-width: 1350px) {
//     font-size: 26px;
//   }
//   @media screen and (max-width: 1000px) {
//     font-size: 20px;
//   }
//   @media screen and (max-width: 600px) {
//     font-size: 32px;
//   }
// `

//
// const Heading = styled(ThemedText.Main)`
const Heading1 = styled.p<{ fontSize: string }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
  color: #5a63ff;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: fit-content;
`

const SubtitleH3 = styled.h3<{ fontSize: string; lineHeight: string }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '35px')};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '58px')};
  font-weight: 600;
  width: fit-content;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 12px;
  color: #242424;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media screen and (max-width: 1350px) {
    font-size: 26px;
  }
  @media screen and (max-width: 1000px) {
    font-size: 20px;
  }
  @media screen and (max-width: 600px) {
    font-size: 32px;
  }
`
const DescriptionP = styled.p`
  color: #000;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  font-family: Poppins;
`
interface ContentImageProps {
  heading?: string | ReactElement
  subtitle?: string | ReactElement
  children?: ReactElement
  imageSrc: string
  headingColor?: string
  TextPosition?: 'TOP' | 'BOTTOM'
  topMargin?: string
}

export default function ContentImageCard({
  subtitle,
  heading,
  children,
  imageSrc,
  headingColor = '#5a63ff',
  TextPosition = 'TOP',
  topMargin = '108px',
}: ContentImageProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useOnScrollVisibility(ref, { freezeOnceVisible: true })
  const isVisible = !!entry?.isIntersecting

  // get localstorage data
  // const userData = localStorage.getItem('redux_localstorage_simple_user')
  // let LocalLanguage = 'en-US'
  // if (userData && typeof userData === 'string') {
  //   const getLanguage = JSON.parse(userData) || ''
  //   LocalLanguage = getLanguage?.userLocale ?? ''
  // }

  return (
    <Box>
      <AnimationRefBox ref={ref}></AnimationRefBox>
      <Container
        marginTop={isMobile ? '40px' : topMargin}
        display={'flex'}
        justifyContent={'start'}
        flexDirection={TextPosition === 'TOP' ? 'column-reverse' : 'column'}
        show={isVisible}
      >
        <ImageContainer>
          <img width={'100%'} src={imageSrc} alt={t`content`}></img>
        </ImageContainer>
        <InfoContainer>
          {heading && <Heading1 fontSize={!isMobile ? '16px' : '12px'}>{heading}</Heading1>}
          {/* {LocalLanguage === 'fr-FR' ? (
            <>
              {subtitle && (
                <SubtitleFRLang width={'fit-content'} fontWeight={600} marginTop={'8px'} marginBottom={'12px'}>
                  {subtitle}
                </SubtitleFRLang>
              )}
            </>
          ) : (
            <> */}
          {subtitle && (
            <SubtitleH3 fontSize={!isMobile ? '35px' : '32px'} lineHeight={!isMobile ? '58px' : '42px'}>
              {subtitle}
            </SubtitleH3>
          )}
          {/* </>
          )} */}
          {children && <DescriptionP>{children}</DescriptionP>}
        </InfoContainer>
      </Container>
    </Box>
  )
}
