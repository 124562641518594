import { Box } from 'rebass'
import { ThemedText } from 'theme'
import styled from 'styled-components/macro'
import { ReactElement, useState } from 'react'
import Collapsible from './Collapsible'
import { Trans } from '@lingui/macro'
import { useLocation } from 'react-router-dom'
import { isMobile } from 'utils/userAgent'
// // eslint-disable-next-line no-restricted-imports
// import { t } from '@lingui/macro'

const Container = styled(Box)`
  margin-top: 108px !important;
  display: flex;
  flex-direction: row;
  padding: 0px;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    align-items: center;
    flex-direction: column !important;
    text-align: center;
  &>div{
    padding: 0px;
    width: 90% !important;
  }
  @media screen and (max-width: 767px){
    margin-top: 40px !important;
  }
`

// const Heading = styled(ThemedText.Main)`
//   font-weight: 700;
//   font-size: 16px;
//   line-height: 24px;
//   letter-spacing: 2px;
//   text-transform: uppercase;
//   color: #5a63ff;
// `

const Description = styled(ThemedText.DarkGray)`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #868686;
`

const AnswerTitle = styled.span`
  font-weight: 700;
`

//
const HeadingH2 = styled.h2<{ fontSize: string }>`
  font-weight: 700;
  font-size: ${({ fontSize }) => fontSize};
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #5a63ff;
  font-family: Poppins;
`
const ThemedTextBlackH2 = styled.h2<{ fontSize: string }>`
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: ${({ fontSize }) => fontSize};
  color: #242424;
`
interface ContentSectionProps {
  heading: string | ReactElement
  subtitle: string | ReactElement
  children: ReactElement
  imageSrc: string
  imageAlt: string
  imagePosition: 'RIGHT' | 'LEFT'
}
export default function QnASection({
  subtitle,
  heading,
  children,
  imageSrc,
  imageAlt,
  imagePosition,
}: ContentSectionProps) {
  const itemsAirdropPage = [
    {
      label: <Trans>What is a Crypto Airdrop?</Trans>,
      id: 1,
      smallAnswer: false,
      open: false,
      children: (
        <Trans>
          {
            'An Airdrop is when a token is sent to a specific group of  crypto wallet addresses. Sometimes this is a marketing strategy by crypto projects to introduce their token to new audiences. In our case, the BEST Token Airdrop rewards early adopters, power users, and brand advocates of Best Wallet and Best DEX with a portion of the total token supply as a way to thank them for their engagement within the Best Ecosystem.'
          }
        </Trans>
      ),
    },
    {
      label: <Trans>How can I participate in the BEST Token Airdrop?</Trans>,
      id: 2,
      smallAnswer: false,
      open: false,
      children: (
        <>
          <div>
            <Trans>To participate in the BEST Token Airdrop, you need to follow these steps:</Trans>
          </div>
          <br />
          <div>
            <Trans>a. Download the Best Wallet app and create a wallet.</Trans>
          </div>
          <div>
            <Trans>b. Visit the Airdrop page on our website.</Trans>
          </div>
          <div>
            <Trans>
              c. Provide your wallet address, email address, Twitter handle, and Discord username and follow our socials
            </Trans>
            .
          </div>
          <div>
            <Trans>d. Engage actively within the Best Ecosystem to earn points.</Trans>
          </div>
        </>
      ),
    },
    {
      label: <Trans>How do I earn points during the Airdrop?</Trans>,
      id: 3,
      smallAnswer: false,
      open: false,
      children: (
        <>
          <div>
            <Trans>During Phase 1-6 of our product launch, points are earned in three ways.</Trans>
          </div>
          <br />
          <div>
            <Trans>a. Engaging with our socials and spreading the word about Best Web3.</Trans>
          </div>
          <div>
            <Trans>b. Using Best Wallet.</Trans>
          </div>
          <div>
            <Trans>c. Using Best DEX.</Trans>
          </div>
        </>
      ),
    },
    {
      label: <Trans>What are the benefits of holding BEST tokens?</Trans>,
      id: 4,
      smallAnswer: false,
      open: false,
      children: (
        <>
          <div>
            <Trans>
              Holding BEST tokens will bring several exclusive benefits within the Best Ecosystem, including:
            </Trans>
          </div>
          <br />
          <div>
            <Trans>a. No gas fees on transactions.</Trans>
          </div>
          <div>
            <Trans>b. Governance voting on Best Dex.</Trans>
          </div>
          <div>
            <Trans>c. Early access to token launches.</Trans>
          </div>
          <div>
            <Trans>d. Participation in exclusive NFT and token drops.</Trans>
          </div>
          <br />
          <div>
            <Trans>We will announce more benefits and utility closer to the airdrop date.</Trans>
          </div>
        </>
      ),
    },
    {
      label: <Trans>When will the Airdrop happen?</Trans>,
      id: 5,
      smallAnswer: false,
      open: false,
      children: (
        <Trans>
          The airdrop ends at the end of phase 6 of our Best Wallet launch. The specific date and time of the Airdrop
          will be announced on our official website and social media channels. Stay tuned for updates to ensure you
          don&apos;t miss out on this opportunity!
        </Trans>
      ),
    },
    {
      label: <Trans>How will the Airdrop rewards be distributed?</Trans>,
      id: 6,
      smallAnswer: false,
      open: false,
      children: (
        <Trans>
          10% of the total supply of the BEST token has been allocated to the airdrop. Participants will earn a share of
          the token pool proportionate to the points they have used throughout phases 1-6 of the Best Wallet launch. The
          Airdrop rewards will be distributed directly to the wallet addresses provided during the registration process.
          Please ensure the accuracy of the wallet address to receive your tokens smoothly.
        </Trans>
      ),
    },
    {
      label: <Trans>Is there a limited number of participants for the Airdrop?</Trans>,
      id: 7,
      smallAnswer: true,
      open: false,
      children: <Trans>Participation is open to all.</Trans>,
    },
    {
      label: <Trans>Can I register multiple times for the Airdrop?</Trans>,
      id: 8,
      smallAnswer: true,
      open: false,
      children: (
        <Trans>
          No, each participant is allowed to register only once for the Airdrop. Multiple registrations may lead to
          disqualification.
        </Trans>
      ),
    },
    {
      label: <Trans>How will you prevent fraudulent activity during the Airdrop?</Trans>,
      id: 9,
      smallAnswer: false,
      open: false,
      children: (
        <Trans>
          We have implemented various security measures to detect and prevent fraudulent activities. Any attempts at
          gaming the system or engaging in suspicious activities will lead to immediate disqualification from the
          Airdrop.
        </Trans>
      ),
    },
    {
      label: <Trans>How can I stay updated about the Airdrop and the project&apos;s progress?</Trans>,
      id: 10,
      smallAnswer: false,
      open: false,
      children: (
        <Trans>
          To stay informed about the Airdrop, project updates, and important announcements, make sure to follow our
          official social media channels and regularly check our website. We will keep the community informed about any
          developments related to the BEST Token Airdrop.
        </Trans>
      ),
    },
  ]

  const itemsLandingPage = [
    {
      label: <Trans>What is Best DEX?</Trans>,
      id: 1,
      smallAnswer: true,
      open: false,
      children: (
        <Trans>
          {
            'Best DEX is a decentralised exchange designed to seamlessly integrate with Best Wallet, our non-custodial crypto wallet . It allows you to trade cryptocurrencies securely and efficiently while maintaining full control of your funds and digital assets.'
          }
        </Trans>
      ),
    },
    {
      label: <Trans>Why should I use Best DEX instead of Uniswap or other DEXs?</Trans>,
      open: false,
      id: 10,
      smallAnswer: false,
      children: (
        <>
          <div>
            <AnswerTitle>
              <Trans>Cost-Saving Advantage</Trans>
              {': '}
            </AnswerTitle>
            <Trans>
              Best DEX automatically matches you to the trading pair with the lowest rate for your desired swap,
              ensuring you always get the best price on your trades. This feature helps you save money on transactions.
            </Trans>
          </div>
        </>
      ),
      collapsible: 'disabled',
    },
    {
      label: <Trans>What are the benefits of Best DEX?</Trans>,
      open: false,
      id: 2,
      smallAnswer: false,
      children: (
        <>
          <div>
            <AnswerTitle>
              <Trans>Market Insights and Analytics</Trans>
              {': '}
            </AnswerTitle>
            <Trans>
              Best DEX provides you with comprehensive market insights and in-depth token analytics, giving you the best
              chance to make the best trading decisions at the right time.
            </Trans>
          </div>
          <br />
          <div>
            <AnswerTitle>
              <Trans>Convenience</Trans>
              {': '}
            </AnswerTitle>
            <Trans>
              Best DEX is built as the sister app to Best Wallet, our flagship{' '}
              <a href="https://bestwallet.com" target="_blank" rel="noreferrer">
                non-custodial crypto wallet
              </a>
              . Best DEX can be accessed from within Best Wallet for an easy trading experience all within a single app.
              No more need to use several different wallets and DEXs to manage your crypto portfolio.
            </Trans>
          </div>
          <br />
          <div>
            <AnswerTitle>
              <Trans>Airdrop Incentives</Trans>
              {': '}
            </AnswerTitle>
            <Trans>
              By using Best DEX, you become part of the Best Ecosystem, and we reward our most loyal and active users
              with a 10% airdrop of the total supply of our native token, $BEST, during Phase 6 of our launch.
            </Trans>
          </div>
        </>
      ),
      collapsible: 'disabled',
    },
    {
      label: <Trans>What are the fees associated with using Best DEX?</Trans>,
      open: false,
      id: 3,
      smallAnswer: true,
      children: (
        <Trans>
          Best Dex does not charge a usage fee. The only fees are determined by the specific liquidity pool and network
          gas fees.
        </Trans>
      ),
    },
    {
      label: <Trans>Which chains does Best DEX support for decentralised trading?</Trans>,
      open: false,
      id: 4,
      smallAnswer: true,
      children: (
        <Trans>
          Best DEX initially supports Ethereum, BNB Smart Chain, and Polygon networks. As we continue to grow, we plan
          to add support for all active major blockchains, broadening your trading options and opportunities.
        </Trans>
      ),
    },
    {
      label: <Trans>Does Best DEX require registration and KYC verification?</Trans>,
      open: false,
      smallAnswer: true,
      id: 5,
      children: (
        <Trans>
          Best DEX does not require KYC verification to start trading. We prioritise user privacy and aim to provide a
          hassle-free onboarding experience. Simply connect your wallet and you’re ready to go.
        </Trans>
      ),
    },
    {
      label: <Trans>Can I use Best DEX on my mobile phone?</Trans>,
      open: false,
      smallAnswer: false,
      id: 6,
      children: (
        <>
          <div>
            <Trans>There are multiple ways to access Best DEX on your mobile device{':'}</Trans>
          </div>
          <br />
          <div>
            <AnswerTitle>
              <Trans>Mobile Web Browser</Trans>
              {': '}
            </AnswerTitle>
            <Trans>
              Simply visit the Best DEX platform through your mobile web browser to start trading cryptocurrencies on
              the go.
            </Trans>
          </div>
          <br />
          <div>
            <AnswerTitle>
              <Trans>{"Best Wallet In-App 'Swap' Feature"}</Trans>
              {': '}
            </AnswerTitle>
            <Trans>
              {
                "If you already have Best Wallet installed, you can access Best DEX directly through the in-app 'swap' feature. This integration provides you with a seamless and convenient trading experience within a single app."
              }
            </Trans>
          </div>
          <br />
          <div>
            <AnswerTitle>
              <Trans>{'Best Wallet dApp (Coming Soon)'}</Trans>
              {': '}
            </AnswerTitle>
            <Trans>
              {
                'In the near future, Best DEX will be available as a decentralised application (dApp) within Best Wallet, offering an even more integrated and user-friendly trading experience.'
              }
            </Trans>
          </div>
        </>
      ),
    },
    {
      label: <Trans>What is the BEST token?</Trans>,
      open: false,
      smallAnswer: false,
      id: 7,
      children: (
        <>
          <div>
            <Trans>
              The BEST token is the Best Ecosystem’s utility and governance token. Holding the token provides benefits
              to Best Wallet users such as advanced market analysis tools, no gas fees on swaps, and early access to new
              token launches and NFT drops.
            </Trans>
          </div>
          <br />
          <div>
            <Trans>
              We are launching the BEST token at the end of Phase 6 of our roadmap and will reward our early adopters
              and power users with 10% of our total supply based on the points they have accumulated from using our app
              and spreading the word on socials. {/*Learn more here */}
            </Trans>
          </div>
        </>
      ),
    },
    {
      label: <Trans>How can I stay up to date on the latest news about Best DEX and the BEST token airdrop?</Trans>,
      open: false,
      smallAnswer: true,
      id: 8,
      children: (
        <Trans>
          Follow our socials and join our community hubs{' '}
          <a href="https://linktr.ee/bestweb3official" target="_blank" rel="noreferrer">
            here
          </a>{' '}
        </Trans>
      ),
    },
  ]
  const location = useLocation()
  const isHomePage = location.pathname === '/'
  const [QnAs, setQnAs] = useState(isHomePage ? itemsLandingPage : itemsAirdropPage)
  const handleCollapsibleClick = (id: number) => {
    const newQnAState = QnAs.map((question) => {
      return question.id === id ? { ...question, open: !question.open } : { ...question, open: false }
    })
    setQnAs(newQnAState)
  }
  return (
    <Container flexDirection={imagePosition === 'LEFT' ? 'row-reverse' : 'row'}>
      <Box padding={imagePosition === 'LEFT' ? '0px 0px 0px 48px' : '0px 32px 0 0'} width={'45%'}>
        <HeadingH2 fontSize={isMobile ? '12px' : '16px'}>{heading}</HeadingH2>

        <ThemedTextBlackH2 fontSize={isMobile ? '32px' : '48px'}>{subtitle}</ThemedTextBlackH2>

        <Description>{children}</Description>
      </Box>
      <Box width={'55%'}>
        {QnAs.map((question) => (
          <Collapsible
            onHeadingClick={() => handleCollapsibleClick(question.id)}
            key={question.id}
            open={question.open}
            title={question.label}
            isSmallAns={question.smallAnswer}
          >
            <>{question.children}</>
          </Collapsible>
        ))}
      </Box>
    </Container>
  )
}
