import { useRef } from 'react'
import { Box } from 'rebass'
// import { ThemedText } from 'theme'
import styled from 'styled-components/macro'
import { ReactElement } from 'react'
// eslint-disable-next-line no-restricted-imports
import { t } from '@lingui/macro'
import useOnScrollVisibility from 'hooks/onScrollVisibility'
import { isMobile } from '../../utils/userAgent'
const Container = styled(Box)<{ show?: boolean; animateFromLeft?: boolean; textAboveInMobile: boolean }>`
  max-width: 1270px;
  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.17, 0.67, 0.52, 0.98);
  opacity: ${({ theme, show }) => (show ? 1 : 0)};
  transform: ${({ theme, show, animateFromLeft }) =>
    show ? 'translateY(0px);' : animateFromLeft ? 'translateX(200px)' : 'translateX(-200px)'};

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    flex-direction: row;
    column-gap: 32px;
  }
  @media screen and (max-width: 767px) {
    align-items: center;
    flex-direction: ${({ theme, textAboveInMobile }) =>
      textAboveInMobile ? 'column !important' : 'column-reverse !important;'};
    gap: 20px;
    text-align: center;
  }
  margin-top: 108px !important;
  margin-left: 90px !important;
  margin-right: 77px !important;
  @media screen and (max-width: 1150px) {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  @media screen and (max-width: 1060px) {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 108px !important;
  }
  @media screen and (max-width: 767px) {
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-top: 40px !important;
  }
`
const AnimationRefBox = styled.div`
  position: relative;
  top: 400px;
`
const InfoContainer = styled.div<{ imagePosition: boolean }>`
  width: 50%;
  padding: ${({ theme, imagePosition }) => (imagePosition ? '0px 50px 0px 48px' : '0px 10% 0 0')};
  @media screen and (max-width: 1150px) {
    padding: ${({ theme, imagePosition }) => (imagePosition ? '0px 30px 0px 40px' : '0px 5% 0 0')};
  }
  @media screen and (max-width: 1000px) {
    width: 90%;
    padding: 0px;
  }
`
const ImageContainer = styled(Box)`
  width: 50%;
  padding: 0px 3px;
  @media screen and (max-width: 1000px) {
    width: 90%;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    // width: 100%;
    padding: 0px;
  }
`

// const Heading = styled(ThemedText.Main)`
const Heading1 = styled.h2<{ fontSize: string }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
  color: #5a63ff;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 8px 0px;

  @media screen and (max-width: 1000px) {
    margin: 0px;
  }
`
const Heading2 = styled.h2<{ fontSize: string; paddingX: string; lineHeight: string }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '48px')};
  padding: ${({ paddingX }) => (paddingX ? paddingX : '0px')};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '58px')};
  color: #242424;
  font-weight: 600;
  text-transform: none;
  margin-top: 8px;
  margin: 8px 0px;

  @media screen and (max-width: 1000px) {
    margin: 0px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin: 0px;
    padding: 0px !important;
  }
  // margin-bottom: 16px;
`
// const Description = styled(ThemedText.DarkGray)`
const Description1 = styled.p<{ fontSize: string }>`
  font-weight: 400;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '20px')};

  line-height: 30px;
  color: #868686;
  @media screen and (max-width: 600px) {
    font-size: 15px;
  }
`

interface ContentSectionProps {
  heading: string | ReactElement
  subtitle: string | ReactElement
  children: ReactElement
  imageSrc: string
  imagePosition: 'RIGHT' | 'LEFT'
  headingColor?: string
  textAboveInMobile?: boolean
}

export default function ContentImageSection({
  subtitle,
  heading,
  children,
  imageSrc,
  imagePosition,
  headingColor = '#5a63ff',
  textAboveInMobile = false,
}: ContentSectionProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useOnScrollVisibility(ref, { freezeOnceVisible: true })
  const isVisible = !!entry?.isIntersecting
  return (
    <>
      <AnimationRefBox ref={ref}></AnimationRefBox>
      <Container
        show={isVisible}
        animateFromLeft={imagePosition === 'LEFT'}
        display={'flex'}
        justifyContent={'space-between'}
        textAboveInMobile={textAboveInMobile}
        alignItems={'center'}
        flexDirection={imagePosition === 'LEFT' ? 'row-reverse' : 'row'}
      >
        <InfoContainer imagePosition={imagePosition === 'LEFT'}>
          <Heading1 fontSize={!isMobile ? '16px' : '12px'}>{heading}</Heading1>

          <Heading2
            fontSize={!isMobile ? '48px' : '32px'}
            paddingX={!isMobile ? '0px' : '5px'}
            lineHeight={!isMobile ? '58px' : '42px'}
          >
            {subtitle}
          </Heading2>

          <Description1 fontSize={!isMobile ? '20px' : '15px'}>{children}</Description1>
        </InfoContainer>
        <ImageContainer>
          <img width={'100%'} src={imageSrc} alt={t`section content`}></img>
        </ImageContainer>
      </Container>
    </>
  )
}
