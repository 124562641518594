import styles from './confirmation.module.scss'
import { Trans } from '@lingui/macro'

interface ConfirmationModalProps {
  modalValue: {
    show: boolean
    message: string
    lineOne?: string
    lineTwo?: string
  }
  onHide: () => void
}

const ConfirmationModal = ({ modalValue, onHide }: ConfirmationModalProps) => {
  if (!modalValue.show) return null

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onHide()
    }
  }

  return (
    <>
      <div className={styles.modalOverlay} onClick={handleOverlayClick}>
        <div className={styles.modal}>
          <div className={styles.confirmationModalWrapper}>
            {modalValue?.message !== 'Success' && (
              <h3>
                <Trans>Error</Trans>
              </h3>
            )}
            <p>{modalValue?.lineOne}</p>
            <p>{modalValue?.lineTwo}</p>
            <div className={styles.btnWrap}>
              <button onClick={onHide}>
                <Trans>OK</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConfirmationModal
