import { useFormik } from 'formik'
import { ChangeEvent, useState } from 'react'
import * as Yup from 'yup'
import ConfirmationModal from './ConfirmationModal'
import partnerPageApi from 'utils/partnerPageApi'
import Loader from 'components/Loader'
import styled from 'styled-components/macro'
import { Link, useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'

const FormContainer = styled.div`
  margin-top: 4.5rem;
  // width: 100%;
  padding: 3rem;
  border-radius: 1.875rem;
  background: #f1f1f1;

  @media screen and (max-width: 800px) {
    padding: 48px 16px;
  }
  @media screen and (max-width: 1000px) {
    padding: 48px 16px;
  }
`
const FormHeading = styled.p`
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 4.25rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 767px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`
const Section = styled.div`
  display: flex;
  gap: 0 1rem;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`
const MarginTop = styled.div`
  margin-top: 1.5rem;
`
const CheckboxWapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
`
const FormFieldWrapper = styled.div`
  width: 100%;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`
const FormLabelFirstRow = styled.p`
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ##727272;
  margin-bottom: 10px;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
  }
`

const FormLabel = styled.p`
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ##727272;
  margin-bottom: 10px;
`
const FormError = styled.p`
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  color: red;
  margin-top: 10px;
  @media screen and (max-width: 800px) {
    font-size: 13px;
  }
`
const InputField = styled.input`
  width: 100%;
  height: 64px;
  padding: 16px 24px;
  border: 1px solid white;
  border-radius: 24px;

  ::placeholder {
    color: #b0b0b0;
    @media screen and (min-width: 801px) and (max-width: 940px) {
      font-size: 13px;
    }
    @media screen and (max-width: 300px) {
      font-size: 13px;
    }
  }

  :focus {
    outline: none;
    border: 1px solid #727272;
    // color: #727272;
  }
`
const FormButton = styled.button`
  width: 100%;
  height: 64px;
  padding: 16px 24px;
  border: 1px solid white;
  border-radius: 40px;
  background: #5a63ff;
  color: #ffffff;
  cursor: pointer;
  margin-top: 24px;
`
const CheckBoxSpan = styled.span`
  color: #000;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 10px;
`
const UndecoratedRouterLink = styled(Link)`
  color: #5a63ff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
`

const messages = {
  firstName: 'First Name',
  lastName: 'Last Name',
  yourWorkEmail: 'Your work Email',
  urlTitle: 'URL',
  tellUsAboutYourProject: 'Tell us about your project',
  firstNameRequired: 'First name is required.',
  firstNameLeast2Characters: 'First name must be at least 2 characters.',
  firstNameExceed20Characters: "First name can't exceed 20 characters.",
  lastNameRequired: 'Last name is required.',
  lastNameLeast2Characters: 'Last name must be at least 2 characters.',
  lastNameExceed20Characters: "Last name can't exceed 20 characters.",
  useCharactersOnly: 'Use characters only.',
  invalidEmail: 'Invalid email.',
  emailIsRequired: 'Email is required.',
  useValidURL: 'Use a valid URL.',
  urlIsRequired: 'URL is required.',
  projectInfoRequired: 'Project Info is required.',
  pleaseAcceptTermsAndConditions: 'Please accept Terms & Conditions.',
  thanks: 'Thanks!',
  weWilltouchSoon: 'We’ll be in touch soon.',
  thereIsErr: 'There is an error in submitting the request. Please try after sometime.',
}

export const fieldsRegex = {
  nameValidate: /^[a-zA-Z]*$/,
  emailValidate:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  urlValidate: /^https?:\/\/[^\s/$.?#].[^\s]*$/,
}

const PartnerApplicationForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const [modalShow, setModalShow] = useState({
    show: false,
    message: '',
    lineOne: '',
    lineTwo: '',
  })

  const { i18n } = useLingui()
  const LocalLanguage = i18n._locale

  const handleModalClose = () => {
    setModalShow({
      ...modalShow,
      show: false,
      message: '',
      lineOne: '',
      lineTwo: '',
    })

    if (modalShow?.message === 'Success') {
      history.push('/')
    }
  }
  const initialValues = {
    fname: '',
    lname: '',
    email: '',
    url: '',
    projectInfo: '',
    checkValue: false,
  }

  const inputhandle = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    let trimmedValue = value

    if (name !== 'projectInfo') {
      trimmedValue = value.trim() // Trim the input value
    }

    setValues((prevValues) => ({
      ...prevValues,
      [name]: trimmedValue,
    }))
  }

  const partnerFormSchema = Yup.object({
    fname: Yup.string()
      .min(2, messages.firstNameLeast2Characters)
      .max(20, messages.firstNameExceed20Characters)
      .matches(fieldsRegex.nameValidate, messages.useCharactersOnly)
      .required(messages.firstNameRequired),
    lname: Yup.string()
      .min(2, messages.lastNameLeast2Characters)
      .max(20, messages.lastNameExceed20Characters)
      .matches(fieldsRegex.nameValidate, messages.useCharactersOnly)
      .required(messages.lastNameRequired),
    email: Yup.string().matches(fieldsRegex.emailValidate, messages.invalidEmail).required(messages.emailIsRequired),
    url: Yup.string().matches(fieldsRegex.urlValidate, messages.useValidURL).required(messages.urlIsRequired),
    projectInfo: Yup.string().required(messages.projectInfoRequired),
    checkValue: Yup.boolean()
      .oneOf([true], messages.pleaseAcceptTermsAndConditions)
      .required(messages.pleaseAcceptTermsAndConditions),
  })

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setErrors, setValues, setTouched } =
    useFormik({
      initialValues,
      validationSchema: partnerFormSchema,
      onSubmit: async (values, { setSubmitting, resetForm }) => {
        setTouched({ ...touched, checkValue: true }) // Manually set touched state for checkValue
        try {
          // Perform form submission logic here
          setIsLoading(true)
          partnerPageApi({
            firstname: values.fname,
            lastname: values.lname,
            email: values.email,
            website: values.url,
            moreinfo: values.projectInfo,
          }).then((res) => {
            if (res?.message === 'Success') {
              setIsLoading(false)
              setModalShow({
                ...modalShow,
                show: true,
                message: res?.message,
                lineOne: i18n._(messages['thanks']),
                lineTwo: i18n._(messages['weWilltouchSoon']),
              })
              resetForm()
            } else {
              setIsLoading(false)
              setModalShow({
                ...modalShow,
                show: true,
                message: res?.message,
                lineOne:
                  LocalLanguage === 'en-US'
                    ? 'There is an error in submitting the request.'
                    : i18n._(messages['thereIsErr']),
                lineTwo: LocalLanguage === 'en-US' ? 'Please try after sometime.' : '',

                // lineOne: 'There is an error in submitting the request.',
                // lineTwo: 'Please try after sometime.',
              })
            }
          })
        } catch (error) {
          setErrors({ ...errors, submit: 'An error occurred during form submission' } as any)
        }
        setSubmitting(false)
      },
    })

  return (
    <div>
      <FormContainer>
        <FormHeading>
          <Trans>Showcase Application</Trans>
        </FormHeading>
        <form onSubmit={handleSubmit}>
          <Section>
            <FormFieldWrapper>
              <FormLabelFirstRow>
                <Trans>First Name</Trans>
              </FormLabelFirstRow>
              <InputField
                type="text"
                id="fname"
                name="fname"
                placeholder={i18n._(messages['firstName'])}
                value={values.fname}
                onChange={inputhandle}
                onBlur={handleBlur}
              />
              {touched.fname && <FormError>{errors.fname && i18n._(errors.fname)}</FormError>}
            </FormFieldWrapper>

            <FormFieldWrapper>
              <FormLabelFirstRow>
                <Trans>Last Name</Trans>
              </FormLabelFirstRow>

              <InputField
                type="text"
                id="lname"
                name="lname"
                placeholder={i18n._(messages['lastName'])}
                value={values.lname}
                onChange={inputhandle}
                onBlur={handleBlur}
              />
              {touched.lname && <FormError>{errors.lname && i18n._(errors.lname)}</FormError>}
            </FormFieldWrapper>

            <FormFieldWrapper>
              <FormLabelFirstRow>
                <Trans>Your work Email</Trans>
              </FormLabelFirstRow>
              <InputField
                type="email"
                id="email"
                name="email"
                placeholder={i18n._(messages['yourWorkEmail'])}
                value={values.email}
                onChange={inputhandle}
                onBlur={handleBlur}
              />
              {touched.email && <FormError>{errors.email && i18n._(errors.email)}</FormError>}
            </FormFieldWrapper>
          </Section>
          <MarginTop>
            <FormLabel>
              <Trans>Provide a link to your website</Trans>
            </FormLabel>
            <InputField
              type="text"
              id="url"
              name="url"
              placeholder={i18n._(messages['urlTitle'])}
              value={values.url}
              onChange={inputhandle}
              onBlur={handleBlur}
            />
            {touched.url && <FormError>{errors.url && i18n._(errors.url)}</FormError>}
          </MarginTop>
          <MarginTop>
            <FormLabel>
              <Trans>More Information</Trans>
            </FormLabel>
            <InputField
              id="projectInfo"
              name="projectInfo"
              placeholder={i18n._(messages['tellUsAboutYourProject'])}
              value={values.projectInfo}
              onChange={inputhandle}
              onBlur={handleBlur}
            />
            {touched.projectInfo && <FormError>{errors.projectInfo && i18n._(errors.projectInfo)}</FormError>}
          </MarginTop>
          <MarginTop>
            <CheckboxWapper>
              <input
                type="checkbox"
                id="checkValue"
                name="checkValue"
                checked={Boolean(values.checkValue)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <CheckBoxSpan>
                <Trans>
                  {'I confirm I have read agree to the '}
                  <UndecoratedRouterLink to={'/privacy'}>Privacy Policy</UndecoratedRouterLink>.
                </Trans>
              </CheckBoxSpan>
            </CheckboxWapper>
            {touched.checkValue && <FormError>{errors.checkValue && i18n._(errors.checkValue)}</FormError>}
          </MarginTop>
          <FormButton type="submit">
            {isLoading ? (
              <Loader stroke="#F1f1f1" />
            ) : (
              <span>
                <Trans>Apply</Trans>
              </span>
            )}
          </FormButton>
        </form>
      </FormContainer>
      <ConfirmationModal modalValue={modalShow} onHide={handleModalClose} />
    </div>
  )
}

export default PartnerApplicationForm
