import { Trans } from '@lingui/macro'
import useScrollPosition from '@react-hook/window-scroll'
import { useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Box } from 'rebass'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ThemedText } from '../../theme'
import Menu from '../Menu'
import Modal from '../Modal'
import Row from '../Row'
import Web3Status from '../Web3Status'
import UniBalanceContent from './UniBalanceContent'
import BestDexHero from '../../assets/svg/best_DEX_Hero.svg'
import { darken } from 'polished'
import { isMobile } from '../../utils/userAgent'
import Button from 'components/@common/button'
import useContentPage from 'hooks/useContentPage'
import { useLocation } from 'react-router-dom'
import { CLIENT, CLIENT_BEST_WALLET } from 'constants/params'
import { useEffect } from 'react'
// import { getMobileOperatingSystem } from '../../utils/userAgent'

const HeaderFrame = styled.div<{ showBackground: boolean; isHome: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 83px;
  top: 0;
  position: relative;
  padding: 0.5rem;
  z-index: 21;
  position: relative;
  /* Background slide effect on scroll. */
  /* 
  background-image: ${({ theme, isHome }) =>
    `linear-gradient(to bottom, transparent 50%, ${isHome ? '#5a63ff96' : theme.bg0} 50% )}}`};
  */
  background: #5a63ff96;
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px
    ${({ theme, showBackground, isHome }) => (showBackground ? (isHome ? '#5a63ff96' : theme.bg2) : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;

  @media screen and (min-width: 768px) and (max-width: 1440px) {
    padding: 0;
  }
`
const HeaderFrameInner = styled.div<{ gridFr: string }>`
  display: grid;
  width: 100%;
  max-width: 1440px;
  grid-template-columns: ${({ theme, gridFr }) => gridFr + ' 1fr ' + gridFr};
  // justify-content: space-between;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  ${({ theme, gridFr }) => theme.mediaWidth.upToLarge`
    grid-template-columns: ${gridFr} 1fr ${gridFr};
    justify-content: space-around;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding:  1rem;
    grid-template-columns: 1fr 500px;
  `};
  @media screen and (min-width: 1151px) and (max-width: 1440px) {
    padding: 0px 80px;
  }
  @media screen and (min-width: 1061px) and (max-width: 1150px) {
    padding: 0px 30px;
  }
  @media screen and (min-width: 1025px) and (max-width: 1060px) {
    padding: 0px 50px;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 0px 40px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding:  8px 12px;
    grid-template-columns: 130px 1fr;
  `};
`
const HeaderControls = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  gap: 15px;
  @media screen and (max-width: 1440px) {
    padding-right: 0px !important;
    gap: 8px;
  }
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 23px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`

const HeaderLinks = styled(Row)`
  justify-self: center;
  background-color: ${({ theme }) => theme.bg0};
  width: fit-content;
  padding: 4px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    justify-self: start;  
    `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: center;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    z-index: 99;
    position: fixed;
    bottom: 0; right: 50%;
    transform: translate(50%,-50%);
    margin: 0 auto;
    background-color: ${({ theme }) => theme.bg0};
    border: 1px solid ${({ theme }) => theme.bg2};
    box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
  `};
`
const LogoBox = styled(Box)`
  padding-left: 83px;
  @media screen and (max-width: 600px) {
    padding-left: 0px;
  }
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    padding-left: 0px !important;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding-left: 0px;
  }
  @media screen and (width: 1334px) and (height: 750px) {
    padding-left: 62px;
  }
`

const HeaderLinksForSm = styled(HeaderLinks)`
  visibility: hidden;
`
const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  text-decoration: none;
  justify-self: flex-start;
  margin-right: 12px;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: flex-start;
  `};
  :hover {
    cursor: pointer;
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  font-weight: 500;
  padding: 8px 12px;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    justify-content: center;
    color: ${({ theme }) => theme.primaryText2};
    background-color: ${({ theme }) => theme.swapBg1};
    &:hover {
      background-color: ${({ theme }) => darken(0.05, theme.swapBg1)};
    }
    &:active {
      background-color: ${({ theme }) => darken(0.1, theme.swapBg1)};
    }
  }
`
const PageLinks = styled.a`
  padding: 12px 8px 12px 4px;
  font-weight: 600;
  font-size: 15px;
  line-height: normal;
  color: white;
  cursor: pointer;
  text-decoration: none;
  :hover {
    color: #75ffff;
  }
  @media screen and (max-width: 600px) {
    margin-right: 0px !important;
  }
`
const HideForMobileText = styled(ThemedText.White)`
  width: 150px;
  font-size: 24px;

  @media screen and (max-width: 1000px) {
    font-size: 17px;
    width: 100px;
  }
  @media screen and (max-width: 600px) {
    // display: none !important;
  }
`
const ResponseImage = styled.img`
  width: 60px;
  @media screen and (max-width: 1000px) {
    width: 40px;
  }
`
const HomeLinks = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  gap: 40px;
  font-size: 15px;
`
// const UndecoratedLink = styled(Link)`
//   cursor: pointer;
//   text-decoration: none;
// `
const UndecoratedRouterLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
`
// const StickyNote = styled(Box)`
//   cursor: pointer;
// `
const UndecoratedLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`

export default function Header() {
  const history = useHistory()

  const [enableLink, setEnableLink] = useState(false)

  const isContentPage = useContentPage()
  const searchParams = new URLSearchParams(useLocation().search)
  const clientType = searchParams.get(CLIENT)

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  const scrollY = useScrollPosition()

  const [widthEx, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const onClickScroll = () => {
    setEnableLink(true)
    history.push('/')
    setTimeout(() => {
      const element = document.getElementById('features-scroll')
      if (element) {
        element.scrollIntoView({
          behavior: 'auto',
          block: 'start',
        })
      }
      setEnableLink(false)
    }, 500)
  }

  return (
    <Box width={'100%'}>
      <HeaderFrame isHome={isContentPage} showBackground={isContentPage || scrollY > 45}>
        <HeaderFrameInner gridFr={isContentPage ? 'auto' : '200px'}>
          <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
            <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
          </Modal>
          {clientType !== CLIENT_BEST_WALLET ? (
            <Title href=".">
              <UndecoratedRouterLink to="/">
                <LogoBox display={'flex'}>
                  <ResponseImage src={BestDexHero} alt="logo" />
                  <HideForMobileText fontWeight={700} display={'flex'} alignItems={'center'}>
                    Best DEX
                  </HideForMobileText>
                </LogoBox>
              </UndecoratedRouterLink>
            </Title>
          ) : (
            <div></div>
          )}
          {!isContentPage && clientType !== CLIENT_BEST_WALLET ? (
            <HeaderLinks>
              <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
                <Trans>Swap</Trans>
              </StyledNavLink>
              <StyledNavLink
                id={`pool-nav-link`}
                to={'/pool'}
                isActive={(match, { pathname }) =>
                  Boolean(match) ||
                  pathname.startsWith('/add') ||
                  pathname.startsWith('/remove') ||
                  pathname.startsWith('/increase') ||
                  pathname.startsWith('/find')
                }
              >
                <Trans>Pool</Trans>
              </StyledNavLink>
            </HeaderLinks>
          ) : (
            <HeaderLinksForSm></HeaderLinksForSm>
          )}
          <HeaderControls paddingRight={isContentPage ? '77px' : '0px'}>
            <HeaderElement>{<Box></Box>}</HeaderElement>
            <HeaderElement>
              {/******* Temp. hiding trade button ********/}
              {/* {isContentPage && !isMobile && (
              <PageLinks href="https://bestwallet.com/en/airdrop" target="_blank">
                Airdrop`
              </PageLinks>
            )} */}
              {isContentPage && widthEx > 1025 ? (
                <>
                  <HomeLinks>
                    {/* <PageLinks href="#features-scroll">
                    <Trans>Features</Trans>
                  </PageLinks> */}
                    {/* <PageLinks href={enableLink ? 'javascript:void(0)' : undefined} onClick={onClickScroll}>
                    Features
                  </PageLinks> */}
                    <PageLinks href={process.env.REACT_APP_BESTDEXINFO}>
                      <Trans>Overview</Trans>
                    </PageLinks>

                    <PageLinks href={enableLink ? '#features-scroll' : undefined} onClick={onClickScroll}>
                      <Trans>Features</Trans>
                    </PageLinks>
                    <PageLinks href="https://bestwallet.com" target="_blank">
                      Best Wallet
                    </PageLinks>
                    {/* <PageLinks href="#/swap">
                  <Trans>Trade</Trans>
                </PageLinks> */}
                    <UndecoratedLink href={'/app/'}>
                      <Button
                        fontColor={'#4B53D5'}
                        size={'MEDIUM'}
                        borderRadius={'10px'}
                        type={'HERO'}
                        text={<Trans>Trade</Trans>}
                        fontWeight={600}
                        // onClick={() => history.push('/swap')}
                      ></Button>
                    </UndecoratedLink>
                  </HomeLinks>
                  <HeaderElement>{<Menu langShortHand={isMobile} />}</HeaderElement>
                </>
              ) : (
                <Web3Status />
              )}
              {/* if it not homepage dont show wallet details */}
              {/* <Web3Status /> */}
            </HeaderElement>
            {/* if it not homepage and mobile dont show on header, instead show in fade in nav bar */}
            {/* {!(isContentPage && isMobile) && <HeaderElement>{<Menu langShortHand={isMobile} />}</HeaderElement>} */}
          </HeaderControls>
        </HeaderFrameInner>
      </HeaderFrame>
      {/* hiding the sticky note as it not needed for now */}
      {/* {isContentPage && (
        <UndecoratedLink href="https://bestwallet.com/en" target="_blank">
          <StickyNote
            backgroundColor={'#969BFF'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'38px'}
          >
            <ThemedText.Black fontWeight={'600'} color={'#3C42AA'} fontSize={isMobile ? '11px' : '15px'}>
              <Trans>Join the Best Wallet Waitlist to participate in the BEST Airdrop</Trans>
            </ThemedText.Black>
          </StickyNote>
        </UndecoratedLink>
      )} */}
    </Box>
  )
}
