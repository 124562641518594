import React, { ReactElement, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Box } from 'rebass'
// import { ThemedText } from 'theme'
import styled from 'styled-components/macro'
import { isMobile } from 'utils/userAgent'
interface IProps {
  open?: boolean
  title: string | ReactElement
  isSmallAns: boolean
  onHeadingClick: () => void
  children: ReactElement
}

const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  gap: 10px;
  // height: 75px;
  background: #efefef;
  border-radius: 20px;
  cursor: pointer;
`
// const HeaderText = styled(ThemedText.Black)`
//   font-weight: 600 !important;
//   font-size: 20px;
//   line-height: 24px;
//   text-align: start;
//   color: #2d3280;
//   @media screen and (max-width: 800px) {
//     line-height: 20px;
//   }
// `
const CollapsibleBox = styled(Box)<{ isOpen: boolean; isSmallAns: boolean }>`
  color: #2d3280;
  font-weight: 100;
  text-align: start;
  max-height: ${({ theme, isOpen, isSmallAns }) => (!isOpen ? '0px' : isSmallAns ? '300px' : '1000px')};
  transition: max-height 0.1s;
  @media screen and (max-width: 1200px) {
    max-height: ${({ theme, isOpen, isSmallAns }) => (!isOpen ? '0px' : isSmallAns ? '350px' : '1000px')};
  }
  @media screen and (max-width: 800px) {
    max-height: ${({ theme, isOpen, isSmallAns }) => (!isOpen ? '0px' : isSmallAns ? '400px' : '1000px')};
  }
  @media screen and (max-width: 500px) {
    max-height: ${({ theme, isOpen, isSmallAns }) => (!isOpen ? '0px' : isSmallAns ? '500px' : '1000px')};
  }
`

const HeaderTextP = styled.p<{ fontSize: string }>`
  font-weight: 600 !important;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '20px')};
  line-height: 24px;
  text-align: start;
  color: #2d3280;
  @media screen and (max-width: 800px) {
    line-height: 20px;
  }
`
// const CollapsibleBoxP = styled.p<{ isOpen: boolean; isSmallAns: boolean; fontSize: string }>`
//   color: #2d3280;
//   margin-bottom: 8px;
//   padding: 0px 54px;
//   font-weight: 100;
//   text-align: start;
//   overflow: hidden;
//   max-height: ${({ theme, isOpen, isSmallAns }) => (!isOpen ? '0px' : isSmallAns ? '300px' : '1000px')};
//   transition: max-height 0.5s, padding 0.3s;
//   @media screen and (max-width: 1200px) {
//     max-height: ${({ theme, isOpen, isSmallAns }) => (!isOpen ? '0px' : isSmallAns ? '350px' : '1000px')};
//   }
//   @media screen and (max-width: 800px) {
//     max-height: ${({ theme, isOpen, isSmallAns }) => (!isOpen ? '0px' : isSmallAns ? '400px' : '1000px')};
//   }
//   @media screen and (max-width: 500px) {
//     max-height: ${({ theme, isOpen, isSmallAns }) => (!isOpen ? '0px' : isSmallAns ? '500px' : '1000px')};
//   }
// `
const Collapsible: React.FC<IProps> = ({ open, children, title, onHeadingClick, isSmallAns }: IProps) => {
  const [isOpen, setIsOpen] = useState(open)

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev)
    onHeadingClick()
  }

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <>
      <Box>
        <Header marginBottom={'1px'} onClick={handleFilterOpening} backgroundColor={isOpen ? '#EFEFFF' : '#efefef'}>
          <HeaderTextP fontSize={isMobile ? '15px' : '20px'}>{title}</HeaderTextP>
          {!isOpen ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}
        </Header>
        <Box className="border-bottom">
          <Box>
            {
              <CollapsibleBox
                isSmallAns={isSmallAns}
                isOpen={isOpen ?? false}
                overflow={'hidden'}
                marginBottom={'8px'}
                paddingX={'54px'}
                paddingTop={isOpen ? '16px' : '0px'}
                paddingBottom={isOpen ? '32px' : '0px'}
                fontSize={isMobile ? '15px' : '16px'}
              >
                {children}
              </CollapsibleBox>
            }
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Collapsible
