import styled from 'styled-components/macro'
import { Box } from 'rebass'
import { Trans } from '@lingui/macro'
import { isMobile } from 'utils/userAgent'
import Footer from 'components/Footer/Footer'
import Description from './Description'
import { ThemedText } from 'theme'
import ContentImageCard from 'pages/Home/ContentImageCard'
// eslint-disable-next-line no-restricted-imports
import { t } from '@lingui/macro'

import BestPlatform from '../../assets/svg/best_platform.svg'
import SocialMedia from '../../assets/svg/social_media_new_twitter.svg'
import TokenStoreImage from '../../assets/svg/token_store_image.svg'
import QnASection from 'pages/Home/QnASection'
import tradeAnything from '../../assets/svg/home-sections/trade_anything.svg'
import { useEffect, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import { RowBetween } from 'components/Row'
import Modal from '../../components/Modal'
import { X } from 'react-feather'
import Button from 'components/@common/button'

const Container = styled.div`
  margin: 58px 120px 0px 120px;
  padding: 0px 120px;
  max-width: 1440px;
  @media screen and (max-width: 1150px) {
    margin: 58px 30px 0px 30px;
  }
  @media screen and (max-width: 1060px) {
    margin: 58px 50px 0px 50px;
  }
  @media screen and (max-width: 600px) {
    margin: 44px 10px 0px 10px;
    padding: 0px;
  }
  @media screen and (min-width: 750px) and (max-width: 1000px) {
    margin: 58px 10px 0px 10px;
    padding: 0px 80px;
  }
  @media screen and (min-width: 1000px) and (max-width: 1450px) {
    margin: 58px 10px 0px 10px;
  }
  @media screen and (min-width: 1000px) and (max-width: 1055px) {
    margin-top: 58px;
    padding: 0px 64px;
  }
  @media screen and (min-width: 1440px) and (max-width: 1686px) {
    margin: 58px 0px 0px 0px;
  }
`

const HeroBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background: linear-gradient(180deg, #5860ff 37.64%, #ffffff 203.72%);
  height: 388px;
  width: 100%;
  padding: 0px 120px;
  @media screen and (max-width: 1150px) {
    padding: 0px 30px;
  }
  @media screen and (max-width: 1000px) {
    padding: 0px 0px 40px 0px;
    height: 388px;
  }
  @media screen and (max-width: 600px) {
    padding: 0px 0px 40px 0px;
    height: 374px;
  }
`

const HeroInner = styled(Box)<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding-top: ${({ isMobile }) => (isMobile ? '82px' : '40px')};
  @media screen and (max-width: 1000px) {
    gap: 20px;
  }
  // p {
  //   &:nth-child(2) {
  //     @media screen and (max-width: 1000px) {
  //       max-width: 450px;
  //     }
  //     @media screen and (max-width: 767px) {
  //       max-width: 322px;
  //       font-size: 16px;
  //       font-style: normal;
  //       font-weight: 400;
  //       line-height: 24px;
  //     }
  //   }
  // }
`

// const HeroTitle = styled(Box)<{ isMobile: boolean }>`
//   color: #fff;
//   text-align: center;
//   font-family: Poppins;
//   font-size: ${({ isMobile }) => (isMobile ? '32px' : '48px')};
//   font-style: normal;
//   font-weight: 600;
//   line-height: 51.6px; /* 120% */
//   letter-spacing: -0.86px;
// `

// const HeroDescription = styled(Box)`
//   color: #fff;
//   text-align: center;
//   font-family: Poppins;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 24px; /* 150% */
// `

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 3%;
  padding: 0px 120px;
  max-width: 1440px;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0;
    padding: 0px 15px;
  }
`
const Section = styled.section`
  margin-top: 12px;
  @media screen and (max-width: 800px) {
    margin-top: 0px;
  }
`
const HoverText = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`

const HeroTitleP = styled.p<{ isMobile: boolean }>`
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: ${({ isMobile }) => (isMobile ? '32px' : '43px')};
  font-style: normal;
  font-weight: 600;
  line-height: 51.6px; /* 120% */
  letter-spacing: -0.86px;
  @media screen and (max-width: 767px) {
    max-width: 322px;
  }
`
const HeroDescriptionP = styled.p`
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  @media screen and (max-width: 1000px) {
    max-width: 450px;
  }
  @media screen and (max-width: 767px) {
    max-width: 322px;
  }
`
const HeaderTextP = styled.p<{ fontSize: string; fontWeight: number }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '48px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 600)};
  font-family: Poppins;
  font-style: normal;
  line-height: 68px;
  text-align: center;
  @media screen and (max-width: 800px) {
    font-size: 32px;
  }
`
const FAQTextP = styled.p<{ fontSize: string }>`
  color: #868686;
  font-family: Poppins;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '20px')};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
`
function Airdrop() {
  const [isSupported, setIsSupported] = useState<any>(null)

  const CardList = [
    {
      id: 'Airdrop_card_1',
      subtitle: t`Use the Best Wallet App and DEX`,
      imageSrc: BestPlatform,
    },
    {
      id: 'Airdrop_card_2',
      subtitle: t`Store your tokens and make transactions`,
      imageSrc: TokenStoreImage,
    },
    {
      id: 'Airdrop_card_3',
      subtitle: t`Engage with our Socials and spread the word`,
      imageSrc: SocialMedia,
    },
  ]

  const togglePopup = () => {
    setIsSupported(true)
  }

  useEffect(() => {
    setIsSupported(true)
    const frame = document.createElement('iframe')
    frame.id = '3pc'
    frame.src = 'https://chamithrepo.github.io/create-third-party-cookie/' // Add your hosted domain URL here
    frame.style.display = 'none'
    frame.style.position = 'fixed'
    document.body.appendChild(frame)

    const handleMessage = (event: any) => {
      if (event.data === '3pcSupported' || event.data === '3pcUnsupported') {
        setIsSupported(event.data === '3pcSupported')
        document.body.removeChild(frame)
        window.removeEventListener('message', handleMessage)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  return (
    <>
      <HeroBanner>
        <HeroInner width={'100%'} isMobile={isMobile}>
          <HeroTitleP isMobile={isMobile}>
            <Trans>Participate in the BEST airdrop</Trans>
          </HeroTitleP>
          <HeroDescriptionP>
            <Trans>
              We’re rewarding all our early adopters with an airdrop of 10% of the total supply of BEST tokens.
            </Trans>
          </HeroDescriptionP>
        </HeroInner>
      </HeroBanner>
      <Description />
      <Section>
        <HeaderTextP fontSize={!isMobile ? '48px' : '32px'} fontWeight={!isMobile ? 600 : 700}>
          <Trans>How you earn points</Trans>
        </HeaderTextP>
        <CardContainer>
          {CardList.map((card) => (
            <ContentImageCard TextPosition={'BOTTOM'} key={card.id} imageSrc={card.imageSrc} topMargin={'58px'}>
              <ThemedText.Black textAlign={'center'}>
                <Trans>{card.subtitle}</Trans>
              </ThemedText.Black>
            </ContentImageCard>
          ))}
        </CardContainer>
      </Section>
      <Container>
        <Box width={'100%'}>
          <QnASection
            imageSrc={tradeAnything}
            imageAlt={t`Frequently Asked`}
            heading={<Trans>Frequently Asked</Trans>}
            subtitle={<Trans>Questions</Trans>}
            imagePosition={'RIGHT'}
          >
            <Box>
              <Box marginBottom={'32px'}>
                <FAQTextP fontSize={isMobile ? '15px' : '20px'}>
                  <Trans>Find answers to the most frequently asked questions about Best Wallet right here.</Trans>
                </FAQTextP>
              </Box>
              <Box fontSize={isMobile ? '15px' : '20px'} marginBottom={'32px'}>
                <FAQTextP fontSize={isMobile ? '15px' : '20px'}>
                  <Trans>
                    If the answer you’re looking for isn’t here, email contact@bestwallet.com and we’ll assist you.
                  </Trans>
                </FAQTextP>
              </Box>
            </Box>
          </QnASection>
        </Box>
      </Container>
      <Footer />
      <Modal isOpen={!isSupported} onDismiss={() => togglePopup()}>
        <AutoColumn gap="12px">
          <RowBetween padding="1rem 1rem 0.5rem 1rem">
            <ThemedText.MediumHeader>
              <Trans>Important Notice</Trans>
            </ThemedText.MediumHeader>
            <HoverText onClick={() => togglePopup()}>
              <X size={24} />
            </HoverText>
          </RowBetween>
          <RowBetween padding="1rem 1rem 0.5rem 1rem">
            <ThemedText.DarkGray>
              <Trans>
                {' '}
                To access the Airdrop page, either switch your browser from incognito mode to normal mode or enable
                third party cookies in your browser.
              </Trans>
            </ThemedText.DarkGray>
          </RowBetween>
          <Box padding="1rem 1rem 0.5rem 1rem" display={'flex'} justifyContent={'end'}>
            <Button onClick={() => togglePopup()} borderRadius={'12px'}>
              <ThemedText.White>
                <Trans>close</Trans>
              </ThemedText.White>
            </Button>
          </Box>
        </AutoColumn>
      </Modal>
    </>
  )
}

export default Airdrop
